import { Button, Grid, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
//import theme from '../../../theme'
import api from "../../api.json";
import axios from "axios";
import "./Classes.css"


import { useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";

const theme = createTheme();
const useStyles = makeStyles((theme) => ({

    textarea: {
        width: "820px",
        [theme.breakpoints.down("xs")]: {
            width: "220px",
        },
    }

}));
const Editclasses = () => {
    const history = useHistory()
    const classes = useStyles();
    const [clsname, setClsName] = useState("");
    const [instructorname, setInstructorName] = useState("");
    const [time, setTime] = useState("");
    const [description, setDescription] = useState("")
    const [style, setStyle] = useState("");
    const [email, setEmail] = useState("");
    const [clastype, setClstype] = useState("");
    const [day, setDay] = useState("")
    const [data, setData] = useState([]);
    const [message, setMessage] = useState("");
const[id,setID]=useState(null);

useEffect(() => {
    setID(localStorage.getItem('ID'))
    setClsName(localStorage.getItem("classname"))
    setStyle(localStorage.getItem("style"))
    setInstructorName(localStorage.getItem("instructorname"))
    setClstype(localStorage.getItem("classtype"))
    setTime(localStorage.getItem("time"))
    setDay(localStorage.getItem("day"))
    setDescription(localStorage.getItem("classaddress"))

}, []);
    
    let handleClick = async (e) => {
        e.preventDefault();



        try {

            let res = await fetch(api.baseUrl + "/api/admin/edit_classes/" + id, {
                method: "PUT",

                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({
                    class_name: clsname,
                    style: style,
                    instructor_name: instructorname,
                    class_type: clastype,
                    time: time,
                    day: day,
                    class_description:description,

                }),
            });



            let resJson = await res.json();
            if (res.status === 200) {

                setMessage("User created successfully");
                history.push("/classeslist")
            } else {
                setMessage("Some error occured");
            }
        } catch (err) {
            console.log(err);


        }
    };
    const handleAllclasses =() =>{
        history.push("/classeslist")
      }
      const handleAddclasses =() =>{
        history.push("/classesadd")
      }
    return (
        <div> <h1 style={{
            fontFamily: 'Aclonica',
            fontStyle: 'normal', fontSize: "25px", marginTop: "50px"
        }}>CLASSES</h1>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>ADD CLASSES</Button>
                <Button onClick={handleAllclasses} style={{ background: "rgba(108, 188, 68, 0.5)", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>ALL CLASSES</Button>
            </div>
            <Grid container spacing={1} style={{ padding: '20px' }}>
                <Grid item xs={12} sm={6} >
                    <Typography style={{ marginTop: theme.spacing(3), fontFamily:"Aclonica" }}>CLASS NAME</Typography>
                    <TextField variant="outlined" value={clsname} onChange={(e) => setClsName(e.target.value)} style={{
                        marginBottom: theme.spacing(3),
                        backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000"
                    }} placeholder="Hip-hop" InputProps={{ 
                        style: {
                        
                          fontFamily:"Aclonica",
                          fontWeight: 400
                        }
                      }} />
                    <Typography style={{ fontFamily:"Aclonica"}}>INSTRUCTOR NAME</Typography>
                    <TextField placeholder='Karan' InputProps={{ 
                        style: {
                       
                          fontFamily:"Aclonica",
                          fontWeight: 400
                        }
                      }} value={instructorname} onChange={(e) => setInstructorName(e.target.value)} style={{ marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
                    <Typography style={{ fontFamily:"Aclonica"}} >TIME</Typography>
                    <TextField placeholder='16:00 - 17:00' InputProps={{ 
                        style: {
                      
                          fontFamily:"Aclonica",
                          fontWeight: 400
                        }
                      }} value={time} onChange={(e) => setTime(e.target.value)} style={{ marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />

                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography style={{ marginTop: theme.spacing(3) , fontFamily:"Aclonica"}}>STYLE</Typography>
                    <TextField placeholder="choreography" InputProps={{ 
                        style: {
                       
                          fontFamily:"Aclonica",
                          fontWeight: 400
                        }
                      }} value={style} onChange={(e) => setStyle(e.target.value)} style={{ marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
                    <Typography style={{ fontFamily:"Aclonica"}}>CLASS TYPE</Typography>
                    <TextField placeholder='studio' InputProps={{ 
                        style: {
                      
                          fontFamily:"Aclonica",
                          fontWeight: 400
                        }
                      }} value={clastype} onChange={(e) => setClstype(e.target.value)} style={{ marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
                    <Typography style={{ fontFamily:"Aclonica"}}>DAY</Typography>
                    <TextField placeholder='wednesday' InputProps={{ 
                        style: {
                       
                          fontFamily:"Aclonica",
                          fontWeight: 400
                        }
                      }} value={day} onChange={(e) => setDay(e.target.value)} 
                    
                style={{ marginBottom: theme.spacing(3), backgroundColor: "white", 
                borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily:"Aclonica" }} />

                </Grid>
                <div>
          <Typography style={{ marginLeft: theme.spacing(1), fontFamily: "Aclonica" }}>CLASS DESCRIPTION</Typography>
          <textarea className={classes.textarea} value={description} onChange={(e) => setDescription(e.target.value)} style={{

            marginLeft: theme.spacing(1),
            minHeight: "100px",
            maxHeight: "auto", fontFamily: "Aclonica", marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000"
          }} />
        </div>
               
            </Grid>

            <div style={{ textAlign: "center" }}>
                <Button type="submit" style={{ fontFamily:"Aclonica", backgroundColor: "#FF0404", width: "120px", height: "40px", color: "white", borderRadius: "20px" }}>CANCEL</Button>
                <Button type='submit' onClick={handleClick} style={{  fontFamily:"Aclonica",backgroundColor: "#328906", width: "120px",
                 height: "40px", color: "white", borderRadius: "20px", marginLeft: theme.spacing(2) }}>UPDATE</Button>
            </div>
        </div>
    )
}



export default Editclasses