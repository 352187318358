import { Button, FormControl, Grid, InputAdornment, InputLabel, inputLabelClasses, MenuItem, NativeSelect, Select, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
//import theme from '../../../theme'
import api from "../../api.json";
import axios from "axios";
import dayjs from 'dayjs';
import "./Offers.css"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dateicon from "../assets/date.png"
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
const theme = createTheme();
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(0),
    background: "white",
    marginLeft: theme.spacing(-2),
    width: "220px", 

  },

  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    minHeight: 30,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Aclonica',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',

    },

  },

}));
const useStyles = makeStyles((theme) => ({


  textarea: {
    width: "820px",
    [theme.breakpoints.down("xs")]: {
      width: "220px",
    },
  }

}));
const Editoffer = () => {
  const history = useHistory()
  const classes = useStyles();
  const [offername, setOfferName] = useState("");
  const [offercode, setOffercode] = useState("");
  const [discount, setDiscount] = useState("");
  const [applicableclasses, setApplicableclasses] = useState("");
  const [day, setDay] = useState("")
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [open, setOpen] = useState(false);
  const [id, setID] = useState(null);
  const [cate, setCate] = useState([])
  useEffect(() => {
    setID(localStorage.getItem('ID'))
    setOfferName(localStorage.getItem("offername"))
    setOffercode(localStorage.getItem("offercode"))
    setSelectedDate(localStorage.getItem("validityfrom"))
    setSelectedDate1(localStorage.getItem("validitytill"))
    setDiscount(localStorage.getItem("discount"))
    setApplicableclasses(localStorage.getItem("applicablepasses"))
  }, []);
  let handleClick = async (e) => {
    e.preventDefault();



    try {

      let res = await fetch(api.baseUrl + "/api/admin/edit_offers/" + id, {
        method: "PUT",

        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify({

          offer_name: offername,
          offer_code: offercode,
          validity_from: format(new Date(selectedDate), 'yyyy-MM-dd'),
          validity_till: format(new Date(selectedDate1), 'yyyy-MM-dd'),
          dicount: discount,
          applicable_passes: applicableclasses
        }),
      });



      let resJson = await res.json();
      if (res.status === 200) {

        setMessage("User created successfully");
        history.push("/offerslist")
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);


    }
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };
  const handleIconClick = () => {
    setOpen(true);
  };

  const handleAlloffers = () => {
    history.push("/offerslist")
  }
  const getoffersdetails = (e) => {
    axios
      .get(api.baseUrl + "/api/admin/view_offers")
      .then((response) => {
  
        setData(response.data.data);
        setCate(response.data.data)
       console.log("result123-->",response.data.data)
      })
      .catch((err) => {
        console.log("error", err);
      });
  
  }
  useEffect(() => {
         
    getoffersdetails()
  }, []);
  return (
    <div> <h1 style={{
      fontFamily: 'Aclonica',
      fontStyle: 'normal', fontSize: "25px", marginTop: "50px"
    }}>OFFER</h1>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button style={{ background: " #6CBC44", color: "#FFFFFF", fontFamily: "Aclonica", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>
          ADD OFFER</Button>
        <Button onClick={handleAlloffers} style={{
          background: "rgba(108, 188, 68, 0.5)", color: "white", width: 450, fontWeight: 600, fontFamily: 'Aclonica',
          height: "25px", fontFamily: "Aclonica"
        }}>ALL OFFERS</Button>
      </div>
      <Grid container spacing={1} style={{ padding: '20px' }}>
        <Grid item xs={12} sm={6} >
          <Typography style={{ marginTop: theme.spacing(3), fontWeight: 400, fontFamily: "Aclonica" }}>OFFER NAME</Typography>
          <TextField variant="outlined" value={offername} onChange={(e) => setOfferName(e.target.value)} style={{
            marginBottom: theme.spacing(3), fontFamily: "Aclonica",
            backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000"
          }} placeholder="Free Text" InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} />
          <Typography style={{ fontFamily: "Aclonica" }}>VALID FROM</Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
              renderInput={(props) => <TextField {...props} readOnly placeholder='Choose Date'
                style={{
                  fontFamily: "Aclonica", marginBottom: theme.spacing(3), backgroundColor: "white",
                  borderColor: "white", borderRadius: "5px", width: "220px"
                }} />}
              value={selectedDate}
              onChange={(date) => handleDateChange(date)}
              disableMaskedInput
              onBeforeInput={(e) => {
                e.preventDefault();
              }}

              style={{
                fontFamily: "Aclonica", marginBottom: theme.spacing(3), backgroundColor: "white",
                borderColor: "white", borderRadius: "5px", color: "#000000"
              }}
              inputProps={{ disabled: true }}
            />
          </LocalizationProvider>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
  <DatePicker
    renderInput={(props) => (
      <TextField
        {...props}
        readOnly
        placeholder="Choose Date"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={dateicon} alt="Date Icon" />
            </InputAdornment>
          ),
          style: {
            fontFamily: 'Aclonica',
            marginBottom: theme.spacing(3),
            backgroundColor: 'white',
            borderColor: 'white',
            borderRadius: '5px',
            color: 'red',
            width: '220px',
          },
        }}
      />
    )}
    value={selectedDate}
    onChange={(date) => handleDateChange(date)}
    disableMaskedInput
    onBeforeInput={(e) => {
      e.preventDefault();
    }}
    style={{
      fontFamily: 'Aclonica',
      marginBottom: theme.spacing(3),
      backgroundColor: 'white',
      borderColor: 'white',
      borderRadius: '5px',
      color: '#000000',
    }}
    inputProps={{ disabled: false }} // Set disabled to false or remove this line
  />
</LocalizationProvider>
*/}


          <Typography style={{ fontFamily: "Aclonica" }}>DISCOUNT (%)</Typography>
          <TextField placeholder='50%' InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={discount} onChange={(e) => setDiscount(e.target.value)}
          onKeyPress={(e) => {
            const allowedKeys = /[0-9.%]/;
            const key = e.key;
            if (!allowedKeys.test(key)) {
              e.preventDefault();
            }
          }} style={{ fontFamily: "Aclonica", marginBottom: theme.spacing(3), backgroundColor: "white", 
          borderColor: "white", borderRadius: "5px", color: "#000000" }} />

        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ marginTop: theme.spacing(3), fontFamily: "Aclonica" }}>OFFER CODE</Typography>
          <TextField placeholder="Free Text" InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={offercode} onChange={(e) => setOffercode(e.target.value)} style={{ fontFamily: "Aclonica", marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
          <Typography style={{ fontFamily: "Aclonica" }} >VALID TILL</Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
  <DatePicker
    renderInput={(props) => (
      <TextField
        {...props}
        readOnly
        placeholder="Choose Date"
        style={{
          fontFamily: "Aclonica",
          marginBottom: theme.spacing(3),
          backgroundColor: "white",
          borderColor: "white",
          borderRadius: "5px",
         
          width: "220px",
        }}
      />
    )}
    value={selectedDate1}
    onChange={(date) => handleDateChange1(date)}
    disableMaskedInput
    onBeforeInput={(e) => {
      e.preventDefault();
    }}
    style={{
      fontFamily: "Aclonica",
      marginBottom: theme.spacing(3),
      backgroundColor: "white",
      borderColor: "white",
      borderRadius: "5px",
      color: "#000000",
    }}
    inputProps={{ disabled: true }}
    minDate={new Date()} // <-- sets the minimum selectable date to the current date
  />
</LocalizationProvider>
          <Typography style={{ fontFamily: "Aclonica" }}>APPLICABLE PASSES</Typography>
          <FormControl className={classes.error} style={{
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1), minWidth: "250px", fontFamily: 'Aclonica'
          }}>
            <InputLabel id="demo-simple-select-label" sx={{ color: "#590F72", fontWeight: 500 }} InputLabelProps={{
              sx: {
                color: "#590F72",
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#590F72",
                  fontWeight: 500,
                  fontSize: 16,
                  fontFamily: 'Aclonica'
                }
              }
            }}></InputLabel>
            <NativeSelect id="select"

              value={applicableclasses} onChange={(e) => setApplicableclasses(e.target.value)}
              InputLabelProps={{
                sx: {



                  [`&.${inputLabelClasses.shrink}`]: {
                    fontFamily: 'Aclonica',
                    fontWeight: 600,
                    fontSize: 16
                  }
                }
              }}

              className={classes.select}
              input={<BootstrapInput />}
            >
              <option value="" style={{ fontFamily: 'Aclonica', background: "#6CBC44", }}>Drop-in</option>
              <option value="Monthly" >Monthly</option>
             <option value="daily" >Daily</option>
            </NativeSelect>
          </FormControl>

        </Grid>

      </Grid>

      <div style={{ textAlign: "center" }}>
        <Button type="submit" style={{ backgroundColor: "#FF0404", width: "120px", height: "40px", color: "white", fontFamily: "Aclonica", borderRadius: "20px" }}>CANCEL</Button>
        <Button type='submit' onClick={handleClick} style={{ backgroundColor: "#328906", width: "120px", fontFamily: "Aclonica",
         height: "40px", color: "white", borderRadius: "20px", marginLeft: theme.spacing(2) }}>UPDATE</Button>
      </div>
    </div>
  )
}





export default Editoffer