import { Button, Grid, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
//import theme from '../../../theme'
import api from "../../api.json";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import "./Students.css"
import { useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dateicon from "../assets/date.png"
import { format } from 'date-fns';
const theme = createTheme();
const useStyles = makeStyles((theme) => ({


  root: {
    marginTop: '10px',
    fontWeight: 400,
    fontFamily: 'Aclonica'
  },
  textarea: {
    width: "240px",
    [theme.breakpoints.down("xs")]: {
      width: "220px",
    },
  }

}));
const Studentsadd = () => {
  const history = useHistory()
  const classes = useStyles();
  const [name, setName] = useState("");
 
  const [contact, setContact] = useState("");
  const [additionalcontact, setAdditionalcontact] = useState("")
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [id, setID] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [error,setError] = useState("")
  const [selectedTime, setSelectedTime] = useState(null);
  const [editid,seteditid] = useState("")

  let editDetails=[],setvar=null;
  const location = useLocation();

  if(location.state){
  console.log(location.state)
  const data = location.state;
  editDetails =data.state.editdata;
  setvar= data.state.isListEdit
  console.log("edit in add:", data.state.editdata)
  console.log("setvar:",setvar)
  }

  useEffect(() => {
   
      if(editDetails ){
      console.log("edit data in add:",editDetails);
      seteditid(editDetails._id)
      setName(editDetails.student_name)
      setEmail(editDetails.email)
      setAddress(editDetails.address)
      setContact(editDetails.contact_number)
      setAdditionalcontact(editDetails.additional_contact)
      setSelectedDate(editDetails.date_of_birth)
     }      
    
      }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleStudentCancel =()=>{
    history.push('/studentslist')
  }
  let handleStudentAdd = async (e) => {
    e.preventDefault();
    if (!name || !email || !address || !contact || !additionalcontact || !selectedDate) {
      setError({ status: true, message: "Please Enter all Required Fields", type: 'error' });
      console.log("inside if")
      console.log("error",error)
      setTimeout(() => {
       setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    const emailPattern = /^[A-Za-z0-9]+([._%-]*[A-Za-z0-9]+)*@[A-Za-z0-9]+([.-]*[A-Za-z0-9]+)*\.[A-Za-z]{2,4}$/;

    if (!emailPattern.test(email)) {
      setError({ status: true, message: "Please Enter valid email address", type: 'error' });
      console.log("error",error)
       setTimeout(() => {
        setError({ status: false, message: '', type: '' });
       }, 3000);
       return;
     }
     if (contact.length<10) {
      setError({ status: true, message: "Please Enter valid Contact Number", type: 'error' });
      console.log("error",error)
       setTimeout(() => {
        setError({ status: false, message: '', type: '' });
       }, 3000);
       return;
     }
    try {
     let res = await fetch(api.baseUrl + "/api/admin/add_student", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          student_name: name,
          email: email,
          contact_number: contact,
          address: address,
          additional_contact: additionalcontact,
          date_of_birth: selectedDate
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setMessage("User created successfully");
        history.push("/studentslist")
        //window.location.reload();
      } else {
        setError({
          status: true,
          message: resJson.errors.msg,
          type: 'error'
      });
      setTimeout(() => {
          setError({ status: false, message: '', type: '' });
      }, 3000);
     return;
  }
} catch (err) {
  console.log(err)
}
}

  const handleAllstudent = () => {
    history.push("/studentslist")
  }

  const handleStudentEdit = async (e) => {
    console.log("Selected id:", editid);
    if (!name || !email || !address || !contact || !additionalcontact || !selectedDate) {
      setError({ status: true, message: "Please Enter all Required Fields", type: 'error' });
      console.log("inside if")
      console.log("error",error)
      setTimeout(() => {
       setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    const emailPattern = /^[A-Za-z0-9]+([._%-]*[A-Za-z0-9]+)*@[A-Za-z0-9]+([.-]*[A-Za-z0-9]+)*\.[A-Za-z]{2,4}$/;

if (!emailPattern.test(email)) {
  setError({ status: true, message: "Please Enter valid email address", type: 'error' });
  console.log("error",error)
   setTimeout(() => {
    setError({ status: false, message: '', type: '' });
   }, 3000);
   return;
 }
 if (contact.length<10 || additionalcontact.length<10) {
  setError({ status: true, message: "Please Enter valid Contact Number", type: 'error' });
  console.log("error",error)
   setTimeout(() => {
    setError({ status: false, message: '', type: '' });
   }, 3000);
   return;
 }
   try
   {
     let res = await fetch(api.baseUrl + "/api/admin/edit_student/" + editid, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            student_name: name,
            email: email,
            contact_number: contact,
            address: address,
            additional_contact: additionalcontact,
            date_of_birth: selectedDate
          }),
        });
        let resJson = await res.json();
        if (res.status === 200) {
          setMessage("Student updated successfully");
          history.push("/studentslist")
          //window.location.reload();
        } else {
          setError({
            status: true,
            message: resJson.message,
            type: 'error'
        });
        setTimeout(() => {
            setError({ status: false, message: '', type: '' });
        }, 3000);
       return;
    }
  } catch (err) {
    console.log(err)
}
}
const handleNameChange = (e) => {
  const inputValue = e.target.value;
  const validatedInput = inputValue.replace(/[^a-zA-Z\s]/g, ''); // Allow letters only
  const maxLengthInput = validatedInput.slice(0, 15); // Restrict to 15 characters

  setName(maxLengthInput);
};
const handleContactNoChange = (e) => {
  const inputValue = e.target.value;
  const numericInput = inputValue.replace(/[^0-9]/g, ''); // Remove non-digit characters

  if (numericInput.length <= 10) {
    setContact(numericInput);
  }
};
const handleAddContactNoChange = (e) => {
  const inputValue = e.target.value;
  const numericInput = inputValue.replace(/[^0-9]/g, ''); // Remove non-digit characters

  if (numericInput.length <= 10) {
    setAdditionalcontact(numericInput);
  }
};

  return (
    <div> 
      {error && (
                 <div id='error'
                style={{  color: "red",textAlign: "start" }} >&nbsp;&nbsp;&nbsp;&nbsp;{error.message}</div> )}
<h1 style={{
      fontFamily: 'Aclonica',
      fontStyle: 'normal', fontSize: "25px", marginTop: "50px"
    }}>STUDENTS</h1>
      <div style={{ display: "flex", flexDirection: "row" }}>
      {setvar===null && (<Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>ADD STUDENT</Button>)}
      {setvar===true && (<Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>EDIT STUDENT</Button>)}
      {setvar===false && (<Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>VIEW STUDENT</Button>)}
        <Button onClick={handleAllstudent} style={{ background: "rgba(108, 188, 68, 0.5)", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>ALL STUDENTS</Button>
      </div>
      <Grid container spacing={1} style={{ padding: '20px' }}>
        <Grid item xs={12} sm={6} >
          <Typography style={{ marginTop: theme.spacing(3), fontFamily: 'Aclonica' }}>Name<span style={{ color: 'red' }}>*</span></Typography>
          <TextField variant="outlined" disabled={setvar=== false} value={name} onChange={handleNameChange} style={{
            marginBottom: theme.spacing(3), fontFamily: 'Aclonica',
            backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000"
          }} placeholder="Karan" InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} />
          <Typography style={{ fontFamily: 'Aclonica' }}>Contact<span style={{ color: 'red' }}>*</span></Typography>
          <TextField placeholder='+91 8123456789' disabled={setvar=== false} InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={contact} onChange={handleContactNoChange}  
          
            style={{ marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: 'Aclonica' }} />
          <Typography style={{ fontFamily: 'Aclonica' }}>Additional Contact<span style={{ color: 'red' }}>*</span></Typography>
          <TextField placeholder='+91 9874563219' InputProps={{
            style: {
              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={additionalcontact}   disabled={setvar=== false} onChange={handleAddContactNoChange}
         
          style={{ fontFamily: 'Aclonica', marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
          <Typography style={{ fontFamily: 'Aclonica' }}>Dob<span style={{ color: 'red' }}>*</span></Typography>
         
            <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker 
              renderInput={(props) => <TextField {...props} readOnly placeholder=''
                style={{
                  fontFamily: "Aclonica", marginBottom: theme.spacing(3), backgroundColor: "white",
                  borderColor: "white", borderRadius: "5px", color: "red", width: "220px"
                }} />}
              value={selectedDate} disabled={setvar=== false}
              onChange={(date) => handleDateChange(date)}
              maxDate={new Date()}
              disableMaskedInput
              onBeforeInput={(e) => {
                e.preventDefault();
              }}

              style={{
                fontFamily: "Aclonica", marginBottom: theme.spacing(3), backgroundColor: "white",
                borderColor: "white", borderRadius: "5px", color: "#000000"
              }}
              inputProps={{ disabled: true }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ marginTop: theme.spacing(3), fontFamily: 'Aclonica' }}>Email<span style={{ color: 'red' }}>*</span></Typography>
          <TextField placeholder="karan@gmail.com" InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={email} disabled={setvar=== false} onChange={(e) => setEmail(e.target.value)} style={{ fontFamily: 'Aclonica', marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
          <Typography style={{ fontFamily: 'Aclonica' }}>Address<span style={{ color: 'red' }}>*</span></Typography>
          <textarea className={classes.textarea} placeholder='56, 4th cross Rd, Annanagar' InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={address} disabled={setvar=== false} onChange={(e) => setAddress(e.target.value)} style={{


            minHeight: "100px",
            maxHeight: "auto", marginBottom: theme.spacing(3), fontFamily: "Aclonica", backgroundColor: "white", borderColor: "white",
            borderRadius: "5px"
          }} />

        </Grid>
      </Grid>

      <div style={{ textAlign: "center" }}>
        <Button type="submit"onClick={(handleStudentCancel)} style={{ backgroundColor: "#FF0404", width: "120px", fontFamily: 'Aclonica', height: "40px", color: "white", borderRadius: "20px" }}>CANCEL</Button>
        { setvar===true && (<Button type='submit' onClick={handleStudentEdit} style={{ fontFamily: "Aclonica", backgroundColor: "#328906", width: "120px", height: "40px", color: "white", borderRadius: "20px", marginLeft: theme.spacing(2) }}>UPDATE</Button>)}
     { setvar===null && (<Button type='submit' onClick={handleStudentAdd} style={{ fontFamily: "Aclonica", backgroundColor: "#328906", width: "120px", height: "40px", color: "white", borderRadius: "20px", marginLeft: theme.spacing(2) }}>ADD</Button>)}
      </div>
    </div>
  )
}

export default Studentsadd