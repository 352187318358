import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";

import Navbar from "./Navbar";
import Sidenav from "./Sidenav";
import { Switch, Route,Redirect } from "react-router-dom";
import Dashboard from "../BodyComponent/Dashboard/Dashboard";
import BlogComponent from "../BodyComponent/BlogComponent";

import Logout from "../BodyComponent/Logout";
import { useStyles } from "./HeaderStyles";
import Login from "../Login";

import Svg from "../BodyComponent/Svg";


import { useHistory } from 'react-router-dom'


import Studentsadd from "../BodyComponent/Students/Studentsadd";

import Classesadd from "../BodyComponent/Classes/Classesadd";
import Classeslist from "../BodyComponent/Classes/Classeslist";
import Instructorsadd from "../BodyComponent/Instructors/Instructorsadd";
import Passesadd from "../BodyComponent/Passes/Passesadd";
import Offersadd from "../BodyComponent/Offers/Offersadd";
import Studentslist from "../BodyComponent/Students/Studentslist";
import Editstudent from "../BodyComponent/Students/Editstudent";
import Editclasses from "../BodyComponent/Classes/Editclasses";
import Offerslist from "../BodyComponent/Offers/Offerslist";
import Editoffer from "../BodyComponent/Offers/Editoffer";
import Passeslist from "../BodyComponent/Passes/Passeslist";
import Editpasses from "../BodyComponent/Passes/Editpasses";
import Instructorslist from "../BodyComponent/Instructors/Instructorslist";
//import Editinstructor from "../BodyComponent/Instructors/Editinstructor";
import Eventsadd from "../BodyComponent/Events/Eventsadd";
import Eventslist from "../BodyComponent/Events/Eventslist";
import Receiptlist from "../BodyComponent/Billingdashboard/Receiptlist";
import Paymentlist from "../BodyComponent/Billingdashboard/Paymentlist";
import EditStudent1 from "../BodyComponent/Students/EditStudent1";
import Edit_offer from "../BodyComponent/Offers/Edit_offer";
import Edit_classes from "../BodyComponent/Classes/Edit_classes";
export default function HeaderComponent() {
  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("isLoggedIn") === "true");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoginPath, setIsLoginPath] = useState(window.location.pathname === '/'); // New state variable

  const history = useHistory()
  const handleDrawerOpen = () => {
    setMobileOpen(!mobileOpen);
    console.log("sidebaropen")
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
    console.log("sidebarclose")
  };
  useEffect(() => {
    localStorage.setItem("isLoggedIn", isLoggedIn);
  }, [isLoggedIn]);
  useEffect(() => {
    const unlisten = history.listen(() => {
      setIsLoginPath(window.location.pathname === '/');
    });
    return () => {
      unlisten();
    };
  }, [history]);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    history.push('/dashboard');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.clear();
    history.push('/');
  };
  return (
    <div>

      {/* {window.location.pathname !== '/' ? ( */}
      {isLoggedIn && !isLoginPath && (
        <>
          <Navbar handleDrawerOpen={handleDrawerOpen} />
          <Sidenav
            mobileOpen={mobileOpen}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
          />
        </>
      )}
      {/* // registerian our routes  */}
      <Box>
        <Switch>
          {/* <Route path='/' component={<Dashboard />} /> */}
          <Route exact path="/" render={() => <Login handleLoginSuccess={handleLoginSuccess} />} />
          {isLoggedIn ? (
            <>
          <Box className={classes.wrapper}>

          

            <Route exact path='/dashboard' render={() => <Dashboard />} />


            <Route exact path="/studentsadd" render={() => <Studentsadd />} />
            <Route  exact path="/studentslist" render={()=><Studentslist/>}/>
            <Route  exact path="/editstudent" render={()=><Editstudent/>}/>
            <Route exact path="/instructoradd" render={() => <Instructorsadd />} />
            <Route exact path="/instructorlist" render={()=><Instructorslist/>}/>
            <Route exact path="/classesadd" render={() => <Classesadd />} />
            <Route  exact path="/classeslist" render={()=><Classeslist/>}/>
            <Route  exact path="/editclasses" render={()=><Editclasses/>}/>
            <Route  exact path="/edit_classes" render={()=><Edit_classes/>}/>
            <Route exact path="/passesadd" render={() => <Passesadd />} />
            <Route exact path="/passeslist" render={()=><Passeslist/>}/>
            <Route exact path="/editpasses" render={()=><Editpasses/>}/>
            <Route exact path="/offersadd" render={() => <Offersadd/>}/>
            <Route exact path="/offerslist" render={()=><Offerslist/>}/>
            <Route exact path="/editoffers" render={()=><Editoffer/>}/>
            <Route exact path="/edit_offers" render={()=><Edit_offer/>}/>
            <Route exact path="/eventsadd" render={()=><Eventsadd/>}/>
            <Route exact path="/eventslist" render={()=><Eventslist/>}/>
            <Route exact path="/receiptlist" render={()=><Receiptlist/>}/>
            <Route exact path="/paymentlist" render={()=><Paymentlist/>}/>
            <Route exact path="/edit_student" render={()=><EditStudent1/>}/>
          </Box>
          </>
          ) : (
            <Route path="/" render={() => <Redirect to="/" />} />
            )}
        </Switch>
      </Box>
    </div>
  )
}
