import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import theme from "../../../theme"
import { useTheme } from '@mui/material/styles';
//import { makeStyles } from '@material-ui/core';
//import "./Dashboard.css"
import axios from "axios";
import api from "../../api.json"

const Dashboard = () => {
    const theme2=useTheme();
  
    useEffect(() => {
      // Set the background color of the body element to green
      document.body.style.backgroundColor = "rgba(108, 188, 68, 0.3) !important";

      console.log("dashgfhgfhgfhg",document.body.style.backgroundColor = "")
      // Remove the background color when the component unmounts
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);
    const [inputWidth, setInputWidth] = useState("60%");
    const [contacts,setContacts]=useState([])
    const [events,setEvents]=useState([])
    const [qstats, setQuickstats] = useState({ total_students: 0 });

    const [sclasses,setSclasses]=useState([])
  const getDashboardDetails = () => {
        axios.get(api.baseUrl + `/api/admin/getDashboardDetails`)
          .then((getData) => {
            setContacts(getData.data.data);
            
            console.log("hellohjgjh",getData.data.data[0].quickStats,"class->",getData.data.data[0].scheduledClass,"events--------->",getData.data.data[0].events)
            //setEvents(getData.data.quickStats)
            setEvents(getData.data.events);
            setSclasses(getData.data.scheduledClass);
            setQuickstats(getData.data.quickStats);
            console.log("events--------->",getData.data.data[0].events,"class->",getData.data.data[0].scheduledClass,"qqq---->",getData.data.data[0].quickStats)
            console.log("Mounika",qstats.total_students)
    
          })
      }
    
    useEffect(() => {
       
      const updateInputWidth = () => {
        if (window.innerWidth < 320) {
          setInputWidth("60%");
        } else if (window.innerWidth < 1024) {
          setInputWidth("70%");
        } else {
          setInputWidth("440px");
        }
      };
  
      window.addEventListener("resize", updateInputWidth);
      updateInputWidth();
  
      return () => {
        window.removeEventListener("resize", updateInputWidth);
      };
    }, []);
    //const [quickstats, setQuickstats] = useState({ total_students: 0 });

    
    return <div  >
        <h1 style={{
            fontFamily: 'Aclonica',
            fontStyle: 'normal', fontSize: "30px",marginTop:"50px"
        }}>Dashboard</h1>
        <p style={{ marginTop: theme.spacing(3) ,fontFamily:"Aclonica",fontWeight:400}}>Welcome back!</p>
        <Typography style={{
            marginTop: theme.spacing(3), fontFamily: 'Aclonica',
            fontStyle: 'normal', fontWeight: 400, color: "#000000", fontSize: "20px"
        }}>Quick Stats</Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4} xl={2} style={{ marginTop: theme.spacing(3), }}>
                <Card sx={{ minWidth: 155, borderRadius: "20px", minHeight: "70px" }}
                    style={{ backgroundColor: "#FFFFFF", color: "#000000" }} >
                    <CardContent style={{ minHeight: "130px" }}>
                        <Typography style={{ marginBottom: theme.spacing(3),fontFamily:"Aclonica" }}>Total Students </Typography>
                        <Typography variant="h6" component="div" style={{fontFamily:"Aclonica"}}>
                       30
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={2} style={{ marginTop: theme.spacing(3), }}>
                <Card sx={{ minWidth: 155, borderRadius: "20px", minHeight: "70px" }}
                    style={{ backgroundColor: "#FFFFFF", color: "#000000" }} >
                    <CardContent style={{ minHeight: "130px" }}>
                        <Typography style={{ marginBottom: theme.spacing(3) ,fontFamily:"Aclonica"}}>Total Payments </Typography>
                        <Typography variant="h6" component="div" style={{fontFamily:"Aclonica"}}>
                      40
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
          
            <Grid item xs={12} md={6} lg={4} xl={2} style={{ marginTop: theme.spacing(3), }}>
                <Card sx={{ minWidth: 155, borderRadius: "20px", minHeight: "50px", }}
                    style={{ backgroundColor: "#FFFFFF", color: "#000000" }} >
                    <CardContent style={{ minHeight: "130px" }}>
                        <Typography style={{ marginBottom: theme.spacing(3),fontFamily:"Aclonica" }}>Now Students this month </Typography>
                        <Typography variant="h6" component="div" style={{fontFamily:"Aclonica"}}>
                       56
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={2} style={{ marginTop: theme.spacing(3), }}>
                <Card sx={{ minWidth: 155, borderRadius: "20px", minHeight: "50px", }}
                    style={{ backgroundColor: "#FFFFFF", color: "#000000" }} >
                    <CardContent style={{ minHeight: "130px" }}>
                        <Typography style={{ marginBottom: theme.spacing(3) ,fontFamily:"Aclonica"}}>Payments this month </Typography>
                        <Typography variant="h6" component="div" style={{fontFamily:"Aclonica"}}>
                       12
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Typography style={{
            marginTop: theme.spacing(3), fontFamily: 'Aclonica',
            fontStyle: 'normal', fontWeight: 400, color: "#000000", fontSize: "20px",
        }}>Scheduled Classes</Typography>
        <hr style={{ color: "white", borderTop: "2px solid white", width: "auto" }}></hr>

        <Grid container spacing={{xs: 3, md: 6, lg: 35}} display="flex" flexDirection="row" >
            <Grid item xs={12} md={6} lg={4} xl={2} style={{ marginTop: theme.spacing(3) }}>
                <Card sx={{ minWidth: 255, borderRadius: "20px", minHeight: "180px" }}
                 style={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
                    <CardContent style={{ minHeight: "130px" }}>
                        <Typography style={{fontFamily:"Aclonica"}}>HIP-HOP </Typography>
                        <Typography variant="h6" component="div" style={{ marginBottom: theme.spacing(3),color:"rgba(0, 0, 0, 0.5)",
                        fontSize:"16px",fontFamily:"Aclonica" }} >
                            Hiphop classes by Kiran
                        </Typography>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ marginRight: theme.spacing(3) }}>
                                <label style={{fontFamily:"Aclonica"}}>Date</label>
                                <Typography style={{fontSize:"12px",color:"rgba(0, 0, 0, 0.5)",fontFamily:"Aclonica"}}>10th Jan 2023</Typography>
                            </div>
                            <div>
                                <label style={{fontFamily:"Aclonica"}}>Time</label>
                                <Typography style={{fontSize:"12px",color:"rgba(0, 0, 0, 0.5)",fontFamily:"Aclonica"}}>16:00 - 17:00</Typography>
                            </div>
                        </div>
                        <Button style={{textAlign:"center",justifyContent:"center",fontFamily:"Aclonica",
                        background:" #6CBC44",color:"#FFFFFF",minWidth:"200px"}}>View Details</Button>
                    </CardContent>
                </Card>
                
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={2} style={{ marginTop: theme.spacing(3) }}>
                <Card sx={{ minWidth: 255, borderRadius: "20px", minHeight: "180px" }} style={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
                    <CardContent style={{ minHeight: "130px" }}>
                        <Typography style={{fontFamily:"Aclonica"}}>AFRO </Typography>
                        <Typography variant="h6" component="div" style={{fontFamily:"Aclonica", marginBottom: theme.spacing(3),color:"rgba(0, 0, 0, 0.5)",
                        fontSize:"16px" }}>
                            Afro by Divya
                        </Typography>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ marginRight: theme.spacing(3) }}>
                                <label style={{fontFamily:"Aclonica"}}>Date</label>
                                <Typography style={{fontSize:"12px",color:"rgba(0, 0, 0, 0.5)",fontFamily:"Aclonica"}}>10th Jan 2023</Typography>
                            </div>
                            <div>
                                <label style={{fontFamily:"Aclonica"}}>Time</label>
                                <Typography style={{fontSize:"12px",color:"rgba(0, 0, 0, 0.5)",fontFamily:"Aclonica"}}>16:00 - 17:00</Typography>
                            </div>
                        </div>
                        <Button style={{textAlign:"center",justifyContent:"center",
                        background:" #6CBC44",color:"#FFFFFF",minWidth:"200px",fontFamily:"Aclonica"}}>View Details</Button>
                    </CardContent>
                </Card>
                
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={2} style={{ marginTop: theme.spacing(3) }}>
                <Card sx={{ minWidth: 255, borderRadius: "20px", minHeight: "180px" }} style={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
                    <CardContent style={{ minHeight: "130px" }}>
                        <Typography style={{fontFamily:"Aclonica"}}>HIP-HOP </Typography>
                        <Typography variant="h6" component="div" style={{ marginBottom: theme.spacing(3),color:"rgba(0, 0, 0, 0.5)",
                        fontSize:"16px" ,fontFamily:"Aclonica"}}>
                            Hiphop  by Harsha
                        </Typography>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ marginRight: theme.spacing(3) }}>
                                <label style={{fontFamily:"Aclonica"}}>Date</label>
                                <Typography style={{fontSize:"12px",color:"rgba(0, 0, 0, 0.5)",fontFamily:"Aclonica"}}>10th Jan 2023</Typography>
                            </div>
                            <div>
                                <label style={{fontFamily:"Aclonica"}}>Time</label>
                                <Typography style={{fontSize:"12px",color:"rgba(0, 0, 0, 0.5)",fontFamily:"Aclonica"}}>16:00 - 17:00</Typography>
                            </div>
                        </div>
                        <Button style={{textAlign:"center",justifyContent:"center",
                        background:" #6CBC44",color:"#FFFFFF",minWidth:"200px",fontFamily:"Aclonica"}}>View Details</Button>
                    </CardContent>
                </Card>
                
            </Grid>
            
        </Grid>
        <Typography style={{
            marginTop: theme.spacing(3), fontFamily: 'Aclonica',
            fontStyle: 'normal', fontWeight: 400, color: "#000000", fontSize: "20px"
        }}>Events</Typography>
        <hr style={{ color: "white", borderTop: "2px solid white", width: "auto" }}></hr>

        <Grid container spacing={{xs: 3, md: 6, lg: 34}} display="flex" flexDirection="row">
            <Grid item xs={12} md={6} lg={4} xl={2} style={{ marginTop: theme.spacing(3) }}>
                <Card sx={{ minWidth: 255, borderRadius: "20px", minHeight: "180px" }} style={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
                    <CardContent style={{ minHeight: "130px" }}>
                        <Typography style={{fontFamily:"Aclonica"}}>HIP-HOP </Typography>
                        <Typography variant="h6" component="div" style={{ marginBottom: theme.spacing(3),color:"rgba(0, 0, 0, 0.5)",
                        fontSize:"16px",fontFamily:"Aclonica" }}>
                            Hiphop classes by Kiran
                        </Typography>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ marginRight: theme.spacing(3) }}>
                                <label style={{fontFamily:"Aclonica"}}>Date</label>
                                <Typography style={{fontSize:"12px",fontFamily:"Aclonica",color:"rgba(0, 0, 0, 0.5)"}}>10th Jan 2023</Typography>
                            </div>
                            <div>
                                <label style={{fontFamily:"Aclonica"}}>Time</label>
                                <Typography style={{fontSize:"12px",color:"rgba(0, 0, 0, 0.5)",fontFamily:"Aclonica"}}>16:00 - 17:00</Typography>
                            </div>
                        </div>
                        <Button style={{textAlign:"center",justifyContent:"center",
                        background:" #6CBC44",color:"#FFFFFF",minWidth:"200px",fontFamily:"Aclonica"}}>View Details</Button>
                    </CardContent>
                </Card>
                
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={2} style={{ marginTop: theme.spacing(3) }}>
                <Card sx={{ minWidth: 255, borderRadius: "20px", minHeight: "180px" }} style={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
                    <CardContent style={{ minHeight: "130px" }}>
                        <Typography style={{fontFamily:"Aclonica"}}>AFRO </Typography>
                        <Typography variant="h6" component="div" style={{ fontFamily:"Aclonica",marginBottom: theme.spacing(3),color:"rgba(0, 0, 0, 0.5)",
                        fontSize:"16px" }}>
                            Afro by Divya
                        </Typography>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ marginRight: theme.spacing(3) }}>
                                <label style={{fontFamily:"Aclonica"}}>Date</label>
                                <Typography style={{fontSize:"12px",color:"rgba(0, 0, 0, 0.5)",fontFamily:"Aclonica"}}>10th Jan 2023</Typography>
                            </div>
                            <div>
                                <label style={{fontFamily:"Aclonica"}}>Time</label>
                                <Typography style={{fontSize:"12px",fontFamily:"Aclonica",color:"rgba(0, 0, 0, 0.5)"}}>16:00 - 17:00</Typography>
                            </div>
                        </div>
                        <Button style={{textAlign:"center",fontFamily:"Aclonica",justifyContent:"center",
                        background:" #6CBC44",color:"#FFFFFF",minWidth:"200px"}}>View Details</Button>
                    </CardContent>
                </Card>
                
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={2} style={{ marginTop: theme.spacing(3) }}>
                <Card sx={{ minWidth: 255, borderRadius: "20px", minHeight: "180px" }} style={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
                    <CardContent style={{ minHeight: "130px" }}>
                        <Typography style={{fontFamily:"Aclonica"}}>HIP-HOP </Typography>
                        <Typography variant="h6" component="div" style={{ marginBottom: theme.spacing(3),color:"rgba(0, 0, 0, 0.5)",
                        fontSize:"16px",fontFamily:"Aclonica" }}>
                            Hiphop  by Harsha
                        </Typography>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ marginRight: theme.spacing(3) }}>
                                <label style={{fontFamily:"Aclonica"}}>Date</label>
                                <Typography style={{fontFamily:"Aclonica",fontSize:"12px",color:"rgba(0, 0, 0, 0.5)"}}>10th Jan 2023</Typography>
                            </div>
                            <div>
                                <label style={{fontFamily:"Aclonica"}}>Time</label>
                                <Typography style={{fontSize:"12px",fontFamily:"Aclonica",color:"rgba(0, 0, 0, 0.5)"}}>16:00 - 17:00</Typography>
                            </div>
                        </div>
                        <Button style={{textAlign:"center",justifyContent:"center",
                        background:" #6CBC44",color:"#FFFFFF",minWidth:"200px",fontFamily:"Aclonica"}}>View Details</Button>
                    </CardContent>
                </Card>
                
            </Grid>
            
        </Grid>


    </div>;
};

export default Dashboard;
