import React,{useState,useEffect} from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import axios from 'axios';
import api from "../../api.json"
import { Button, Grid, IconButton, Paper, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import editicon from "../assets/editicon.png";
import deleteicon from "../assets/deleteicon.png";
//import "./Students.css";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../theme';
import SearchIcon from '@mui/icons-material/Search';
import Instructorsadd from './Instructorsadd';
const useStyles = makeStyles({
  dialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent', // set background color here
    width:"50px",
    height:"50px"
    },
  },
});
const Instructorslist = () => {
 // const [isEdit,setIsEdit] = useState(false)
    const history = useHistory()
    const classes = useStyles();
    const [data,setData]=useState([])
   let isListEdit;
    const getinstructordetails = (e) => {
        axios
          .get(api.baseUrl + "/api/admin/get_instructors_v3")
          .then((response) => {
    
            setData(response.data.data.reverse());
           console.log("result123-->",response.data.data)
          })
          .catch((err) => {
            console.log("error", err);
          });
    
      }
      const [id, setID] = useState(null);
      const [open, setOpen] = React.useState(false);

    
    
      const handleClose = () => {
        setOpen(false);
      };
      const handleEdit_products = (editdata) => {
     
        console.log("edit data:",editdata)
        
        const data = {isListEdit:true,editdata}
     history.push( '/instructoradd', {state: data});
    
      
      }
      const[contact,setContact]=useState([])
      const [selectedId, setSelectedId] = useState(null);

      const handleClickOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
      };
      
      const handleDeleteInstructor = () => {
        axios
          .delete(api.baseUrl + "/api/admin/delete_instructor_v2/" + selectedId)
          .then((response) => {
            getinstructordetails();
            console.log("deleted")
          })
          .catch((err) => {
            console.log("error", err);
          });
        handleClose();
      };
    
    
      //search bar api
  const requestSearch = (searchedVal) => {
    console.log("searchedVal", searchedVal)
    axios
      .get( api.baseUrl + "/api/admin/searchInstructor/" + searchedVal)
      .then((response) => {
        setContact(response.data.data);
        setData(response.data.data);
        console.log("topup", response.data)
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  
      useEffect(() => {
       
        getinstructordetails()
      }, []);
      const handleAddinstructor =() =>{
        history.push("/instructoradd")
      }
      const handleEdit = (id) => {
        // Handle edit action for row with the given id
      };
      
      const handleDelete = (id) => {
        // Handle delete action for row with the given id
      };
      
      const handleViewButton = (editdata) => {
        console.log("inside view:",editdata)
        const data = {isListEdit:false,editdata}
        history.push( '/instructoradd', {state: data});
      };
  return (
    <div>
        <h1 style={{
            fontFamily: 'Aclonica',
            fontStyle: 'normal', fontSize: "25px", marginTop: "50px"
        }}>INSTRUCTOR</h1>
         <TextField name="search" 
      placeholder="Search Instructor..." sx={{textAlign:"center",width:"40%",
      float:"right",
      backgroundImage: "linear-gradient(92.15deg, rgba(130, 195, 65, 0.5) 0%, rgba(130, 195, 65, 0.5) 62.92%, rgba(237, 254, 43, 0) 140.48%, rgba(237, 254, 43, 0) 140.48%);"}}
          id="standard-bare"
           onChange={(e) => requestSearch(e.target.value)}
          InputProps={{
            style: {
                   color:"white",    
                fontFamily:"Aclonica",
                fontWeight: 400
              },
            endAdornment: (
              <IconButton>
                <SearchIcon    style={ {
                   color:"white",    
                fontFamily:"Aclonica",
                fontWeight: 400
              }}/>
              </IconButton>
            ),
           
          }}
        />
            <div style={{ display: "flex", flexDirection: "row",marginTop:theme.spacing(10) }}>
                <Button onClick={handleAddinstructor} 
               style={{ background: "rgba(108, 188, 68, 0.5)", color: "#FFFFFF", width: 650, fontWeight: 600,
                fontFamily: 'Aclonica', height: "25px" }}>ADD INSTRUCTOR</Button>
                <Button  style={{ background: " #6CBC44", color: "#FFFFFF", width: 650, fontWeight: 600, 
                fontFamily: 'Aclonica', height: "25px" }}>ALL INSTRUCTORS</Button>
            </div>
            <Grid container spacing={2}>
  <Grid item xs={12}>
 
  <TableContainer style={{maxWidth:"100%", overflowX:"auto"}}>
      <Table style={{minWidth:"650px"}}>
    <TableHead>
      <TableRow>
        <TableCell style={{fontFamily:'Aclonica'}}>NAME</TableCell>
        <TableCell style={{fontFamily:'Aclonica'}}>EMAIL</TableCell>
        <TableCell style={{fontFamily:'Aclonica'}}>Contact No.</TableCell>
        <TableCell style={{fontFamily:'Aclonica'}}>CLASS</TableCell>
        <TableCell style={{fontFamily:'Aclonica'}}>STUDENT(Count)</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data.map((row, index) => (
        <TableRow key={row.id} style={{ backgroundColor: index % 2 === 0 ? 'rgba(108, 188, 68, 0.5)' : 'white' }}  sx={{
            "& td": {

            
              alignItems: "center", height: "2px !important",padding: "2px !important"
            }
          }}
>
          <TableCell>{row.instructor_name}</TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell>{row.contact_number}</TableCell>
          <TableCell>{row.class_type}</TableCell>
          <TableCell>{row.class_style}</TableCell>
          <TableCell>   <Button    onClick={() => handleEdit_products(row)}>
          <img src={editicon}  style={{width:"30px"}}/>
            </Button>
            <Button onClick={() => handleClickOpen(row._id)}>
            <img src={deleteicon}  style={{width:"30px"}}/>
            </Button> 
            <Dialog className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"> Delete Instructor ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure want to delete ?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
  <Grid container textAlign="center" spacing={2} style={{marginLeft:theme.spacing(2)}} >
    <Grid item style={{justifyContent:"center"}}>
      <Button onClick={handleClose} style={{background:"#82C341",color:"white",width:"80px",borderRadius:"15px",textAlign:"center"}}>CANCEL</Button>
    </Grid>
    <Grid item>
      <Button onClick={handleDeleteInstructor}  style={{background:"#DC1E1B",color:"white",width:"80px",borderRadius:"15px",textAlign:"center"}} autoFocus>
       DELETE
      </Button>
    </Grid>
  </Grid>
</DialogActions>

      </Dialog>
      
            <Button style={{color:'orange',fontFamily: 'Aclonica'}} onClick={()=>{handleViewButton(row)}}>View More <ChevronRightIcon style={{marginTop:theme.spacing(-0.4),fontWeight:600}}/> </Button></TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

</Grid>
</Grid>

    </div>
  )
}



export default Instructorslist