import { Button, Grid, InputAdornment, InputLabel, inputLabelClasses, MenuItem, NativeSelect, Select, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
//import theme from '../../../theme'
import api from "../../api.json";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import CalendarIcon from '@material-ui/icons/CalendarToday';
const theme = createTheme();
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(0),
        background: "white",
        marginLeft: theme.spacing(-2),
        width: "240px", 

    },

    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        minHeight: 30,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Aclonica',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',

        },

    },

}));
const useStyles = makeStyles((theme) => ({


    root: {
        marginTop: '10px',
        fontWeight: 400,
        fontFamily: 'Aclonica'
    },
    euroSymbol: {
        fontFamily: '"Your Font Name", sans-serif',
        color: '#000000',
        fontFamily: 'Aclonica'
      },
    textarea: {
        width: "240px",
        [theme.breakpoints.down("xs")]: {
            width: "220px",
        },
    }

}));
const Passesadd = () => {
    const history = useHistory()
    const classes = useStyles();
    const [passnumber, setPassnumber] = useState("");
    const [passtype, setPasstype] = useState("");
    const [name, setName] = useState("");
    const [noofclasses, setNoofclasses] = useState("")
    const [description, setDescription] = useState("")
    const [passescost, setPassescost] = useState("")
    const [data, setData] = useState([]);
    const [message, setMessage] = useState("");
    const [value, setValue] = React.useState('');
    const [error,setError] = useState("")
    const [selectedTime, setSelectedTime] = useState(null);
    const [editid,seteditid] = useState("")
  
    let editDetails=[],setvar=null;
    const location = useLocation();
  
    if(location.state){
    console.log(location.state)
    const data = location.state;
    editDetails =data.state.editdata;
    setvar= data.state.isListEdit
    console.log("edit in add:", data.state.editdata)
    console.log("setvar:",setvar)
    }
  
    useEffect(() => {
     
        if(editDetails ){
        console.log("edit data in add:",editDetails);
        seteditid(editDetails._id)
        setPassnumber(editDetails.pass_number)
        setPasstype(editDetails.pass_type)
        setName(editDetails.name)
        setNoofclasses(editDetails.no_of_classes)
        setDescription(editDetails.description)
        setPassescost(editDetails.pass_cost)
        setValue(editDetails.status)
       }      
      
        }, []);
  

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    let handlePassesAdd = async (e) => {
        e.preventDefault();
        if (!passnumber || !passtype || !passescost || !name || !noofclasses || !description || !value) {
          setError({ status: true, message: "Please Enter all Required Fields", type: 'error' });
          console.log("inside if")
          console.log("error",error)
          setTimeout(() => {
           setError({ status: false, message: '', type: '' });
          }, 3000);
          return;
        }
        try {
            let res = await fetch(api.baseUrl + "/api/admin/add_passes", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    pass_number: passnumber,
                    pass_type: passtype,
                    name: name,
                    no_of_classes: noofclasses,
                    description: description,
                    pass_cost: passescost,
                   status: value
                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
                setMessage("User created successfully");
                history.push("/passeslist")
                //window.location.reload();
            } else {
                setError({
                  status: true,
                  message: resJson.errors.msg,
                  type: 'error'
              });
              setTimeout(() => {
                  setError({ status: false, message: '', type: '' });
              }, 3000);
             return;
          }
        } catch (err) {
          console.log(err)
        }
    };
    const handleAllpasses = () => {
        history.push("/passeslist")
    }
    const handlePassesCancel =()=>{
        history.push('/passeslist')
      }
    const handlePassesEdit = async (e) => {
        console.log("Selected id:", editid);
        if (!passnumber || !passtype || !passescost || !name || !noofclasses || !description || !value) {
            setError({ status: true, message: "Please Enter all Required Fields", type: 'error' });
          console.log("inside if")
          console.log("error",error)
          setTimeout(() => {
           setError({ status: false, message: '', type: '' });
          }, 3000);
          return;
        }
         try
       {
         let res = await fetch(api.baseUrl + "/api/admin/edit_passes/" + editid, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                pass_number: passnumber,
                    pass_type: passtype,
                    name: name,
                    no_of_classes: noofclasses,
                    description: description,
                    pass_cost: passescost,
                   status: value
              }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
              setMessage("Passes updated successfully");
              history.push("/passeslist")
              //window.location.reload();
            } else {
              setError({
                status: true,
                message: resJson.errors.msg,
                type: 'error'
            });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
           return;
        }
      } catch (err) {
        console.log(err)
    }
    }
    const [cate, setCate] = useState([])
    const getpassesdetails = (e) => {
        axios
            .get(api.baseUrl + "/api/admin/view_passes")
            .then((response) => {

                setData(response.data.data);
                setCate(response.data.data);
                console.log("result123-->", response.data.data)
            })
            .catch((err) => {
                console.log("error", err);
            });

    }
    useEffect(() => {
        getpassesdetails()
    }, []);
    const handleNameChange = (e) => {
        const inputValue = e.target.value;
        const validatedInput = inputValue.replace(/[^a-zA-Z\s]/g, ''); // Allow letters only
        const maxLengthInput = validatedInput.slice(0, 15); // Restrict to 15 characters
      
        setName(maxLengthInput);
      };
      const handleclass = (e) => {
        const inputValue = e.target.value;
        const numericInputValue = inputValue.replace(/[^0-9]/g, ''); 
          setPassescost(numericInputValue);
        }
        // You can also handle other cases, such as displaying an error message for invalid input
      
    return (
        <div>
            {error && (
                 <div id='error'
                style={{  color: "red",textAlign: "start" }} >&nbsp;&nbsp;&nbsp;&nbsp;{error.message}</div> )}
                 <h1 style={{
            fontFamily: 'Aclonica',
            fontStyle: 'normal', fontSize: "25px", marginTop: "50px"
        }}>PASSES</h1>
            <div style={{ display: "flex", flexDirection: "row" }}>
            {setvar===null && (<Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>ADD PASSES</Button>)}
      {setvar===true && (<Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>EDIT PASSES</Button>)}
      {setvar===false && (<Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>VIEW PASSES</Button>)}
                <Button onClick={handleAllpasses} style={{ background: "rgba(108, 188, 68, 0.5)", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>ALL PASSES</Button>
            </div>
            <Grid container spacing={1} style={{ padding: '20px' }}>
                <Grid item xs={12} sm={6} >
                    <Typography style={{ fontFamily: 'Aclonica' }}>PASS NUMBER<span style={{ color: 'red' }}>*</span></Typography>
                    <TextField placeholder='UGP054'disabled={setvar=== false} InputProps={{
                        style: {

                            fontFamily: "Aclonica",
                            fontWeight: 400
                        }
                    }} value={passnumber}
                        onChange={(e) => setPassnumber(e.target.value)} style={{ minWidth: "240px", fontFamily: 'Aclonica', marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />

                    <Typography style={{ fontFamily: 'Aclonica' }}>NAME<span style={{ color: 'red' }}>*</span></Typography>
                    <TextField placeholder='Free text' InputProps={{
                        style: {

                            fontFamily: "Aclonica",
                            fontWeight: 400
                        }
                    }} value={name} disabled={setvar=== false}
                        onChange={handleNameChange} style={{ minWidth: "240px", marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
                    <Typography style={{ fontFamily: 'Aclonica', }} > DESCRIPTION<span style={{ color: 'red' }}>*</span></Typography>
                    <textarea className={classes.textarea} value={description} disabled={setvar=== false} onChange={(e) => setDescription(e.target.value)} style={{


                        minHeight: "100px",
                        maxHeight: "auto", marginBottom: theme.spacing(3), fontFamily: "Aclonica", backgroundColor: "white", borderColor: "white", borderRadius: "5px"
                    }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography style={{ fontFamily: 'Aclonica', }}>PASS TYPE<span style={{ color: 'red' }}>*</span></Typography>

                    <FormControl className={classes.error}  disabled={setvar=== false} style={{
                        marginLeft: theme.spacing(2),
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(1), minWidth: "250px", fontFamily: 'Aclonica'
                    }}>
                        <InputLabel id="demo-simple-select-label" sx={{ color: "#590F72", fontWeight: 500 }} InputLabelProps={{
                            sx: {
                                color: "#590F72",
                                [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#590F72",
                                    fontWeight: 500,
                                    fontSize: 16,
                                    fontFamily: 'Aclonica'
                                }
                            }
                        }}></InputLabel>
                        <NativeSelect id="select"
                            disabled={setvar=== false}
                            value={passtype} onChange={(e) => setPasstype(e.target.value)}
                            InputLabelProps={{
                                sx: {



                                    [`&.${inputLabelClasses.shrink}`]: {
                                        fontFamily: 'Aclonica',
                                        fontWeight: 600,
                                        fontSize: 16
                                    }
                                }
                            }}

                            className={classes.select}
                            input={<BootstrapInput />}
                        >
                            <option disabled={setvar=== false} value="" style={{ fontFamily: 'Aclonica', background: "#6CBC44", }}>Drop-in</option>
                            <option value="Weekly" >Weekly</option>
             <option value="Monthly" >Monthly</option>
                        </NativeSelect>
                    </FormControl>
                    <Typography style={{ marginTop: theme.spacing(3), fontFamily: 'Aclonica', }}>NO. OF CLASSES<span style={{ color: 'red' }}>*</span></Typography>
                    <TextField placeholder="1" InputProps={{
                        style: {

                            fontFamily: "Aclonica",
                            fontWeight: 400
                        },
                        inputProps: {
                            min: "1" // Set the minimum value to 1
                          }
                    }} value={noofclasses} type= "number"disabled={setvar=== false}
                    onChange={(e) => {
                        let inputValue = e.target.value;
                        // Ensure the input is a positive number
                        if (inputValue < 1) {
                          // If a negative value is entered, set it to 1
                          inputValue = 1;
                        }
                        setNoofclasses(inputValue);
                        }
                    }
                    style={{ minWidth: "240px", fontFamily: 'Aclonica', marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
                    <Typography style={{ fontFamily: 'Aclonica', }}>PASS COST<span style={{ color: 'red' }}>*</span></Typography>
                    <TextField  
                    InputProps={{
                        style: {

                            fontFamily: "Aclonica",
                            fontWeight: 400
                        },
                        startAdornment: (
                          <InputAdornment position="start" >
                            <span className={classes.euroSymbol}>Rs.</span>
                          </InputAdornment>
                        ),
                      }} value={passescost} disabled={setvar=== false} onChange={handleclass} 
                      style={{ minWidth: "240px", fontFamily: 'Aclonica', marginBottom: theme.spacing(3), backgroundColor: "white",
                       borderColor: "white", borderRadius: "5px", color: "#000000" }} />
                    <Typography style={{ fontFamily: 'Aclonica', }}>STATUS<span style={{ color: 'red' }}>*</span></Typography>
                    <FormControl style={{ fontFamily: 'Aclonica', }}>

                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                           // disabled={setvar=== false}
                            style={{ textAlign: "center", marginBottom: theme.spacing(2), fontFamily: 'Aclonica', }}
                        >
                            <FormControlLabel value="ACTIVE" style={{ fontFamily: 'Aclonica', }} control={<Radio style={{ color: "orange", fontFamily: 'Aclonica' }} />}
                                label={<span style={{ fontFamily: 'Aclonica' }}>ACTIVE</span>} />
                            <FormControlLabel value="INACTIVE" style={{ fontFamily: 'Aclonica', }} control={<Radio style={{ color: "orange", fontFamily: 'Aclonica' }} />}
                                label={<span style={{ fontFamily: 'Aclonica' }}>INACTIVE</span>} />
                        </RadioGroup>
                    </FormControl>

                </Grid>

            </Grid>

            <div style={{ textAlign: "center" }}>
                <Button type="submit" onClick={(handlePassesCancel)} style={{ backgroundColor: "#FF0404", width: "120px", height: "40px", color: "white", borderRadius: "20px", fontFamily: 'Aclonica', }}>CANCEL</Button>
                { setvar===true && (<Button type='submit' onClick={handlePassesEdit} style={{ fontFamily: "Aclonica", backgroundColor: "#328906", width: "120px", height: "40px", color: "white", borderRadius: "20px", marginLeft: theme.spacing(2) }}>UPDATE</Button>)}
     { setvar===null && (<Button type='submit' onClick={handlePassesAdd} style={{ fontFamily: "Aclonica", backgroundColor: "#328906", width: "120px", height: "40px", color: "white", borderRadius: "20px", marginLeft: theme.spacing(2) }}>ADD</Button>)}
            </div>
        </div>
    )
}



export default Passesadd