import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import BookIcon from "@material-ui/icons/Book";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import WbCloudyIcon from '@mui/icons-material/WbCloudy';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupIcon from '@mui/icons-material/Group';
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined';
import { NavLink, useHistory } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import image from '../../Components/BodyComponent/assets/image.png'
import path2 from "../../Components/BodyComponent/assets/path2.png"
import dasicon from "../../Components/BodyComponent/assets/dasicon.png"
import payment from "../../Components/BodyComponent/assets/payment 1.png";
import special from "../../Components/BodyComponent/assets/special 1.png";
import training from "../../Components/BodyComponent/assets/training 1.png"
import students from "../../Components/BodyComponent/assets/students.png";
import passes from "../../Components/BodyComponent/assets/passes.png";
import settings from "../../Components/BodyComponent/assets/settings.png";
import Logouticon from "../../Components/BodyComponent/assets/logout.png";
import presentation from "../../Components/BodyComponent/assets/presentation 1.png"
import event from '../../Components/BodyComponent/assets/event 1.png'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Grid, IconButton, Paper, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../theme';
import SearchIcon from '@mui/icons-material/Search';
const useStyles = makeStyles({
  dialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent', // set background color here
    width:"50px",
    height:"50px"
    },
  },
});

export default function SidenavData({ handleDrawerClose }) {
  const classes = useStyles();
  const [isBillingdashboardOpen, setIsBillingDashboardOpen] = useState(false);
  //const [isOffersOpen, setIsOffersOpen] = useState(false);
  const [isInstructorsOpen, setIsInstructorsOpen] = useState(false);
  const [isStocksOpen, setIsStocksOpen] = useState(false);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const [isOffersOpen, setIsOffersOpen] = useState(false);
  const [islogOpen, setIslogOpen] = useState(false);

    const handleOffersClick = () => {
    history.push("/offersadd")
    handleDrawerClose();

  };
  const handleEventsClick = () => {
    history.push("/eventslist")
    handleDrawerClose();

  };
  const handledashboardClick = () => {
    history.push("/dashboard")
    handleDrawerClose();
  };
  const handlestudentsClick = () => {
    history.push("/studentsadd")
    handleDrawerClose();
  };
  const handleclassesClick = () => {
    history.push("/classesadd")
    handleDrawerClose();
  };
  const handleinstructorsClick = () => {
    history.push("/instructoradd")
    handleDrawerClose();
  }
  const handlepassesaddClick = () => {
    history.push("/passesadd")
    handleDrawerClose();
  }
  const handleOpen = () => {
    setIslogOpen(true);  
  }
  const handleClose = () => {
    setIslogOpen(false)
  }
  const superadmin = [
    { 
      label: "United Grooves",
      icon: <img src={path2} alt="Dashboard" style={{width: "20px", paddingBottom: "13px",marginLeft:"5px" }} />,
    },
    {
      label: "Dashboard",
      icon: <img src={dasicon} alt="Dashboard" style={{width: "23px",marginLeft:"5px"}} />,
      link: "/dashboard",

      onClick: () => handledashboardClick()

    },
    {
      label: "Billing Dashboard",
      icon: <img src={payment} alt="Dashboard" style={{ width: "30px" }} />,
      subMenus: [
        {
          label: "Receipts",
          link: "/receiptlist",
        },
        {
          label: "Payments",
          link: "/paymentlist",
        },
      ],
      onClick: () => 
        handleDropdownClick()
    },
    {
      label: "Offers",
      icon: <img src={special} alt="Dashboard" style={{ width: "30px" }} />,
      link: "/offersadd",
      onClick: () => handleOffersClick()
    },


    {
      label: "Instructors",
      icon: <img src={presentation} alt="Dashboard" style={{ width: "30px" }} />,
      link: "/instructoradd",
      onClick: () => handleinstructorsClick()

    },
    {
      label: "Classes",
      icon: <img src={training} alt="Dashboard" style={{ width: "30px" }} />,
      link: "/classesadd",
      onClick: () => handleclassesClick()

    },
    {
      label: "Students",
      icon: <img src={students} alt="Dashboard" style={{ width: "30px" }} />,
      link: "/studentsadd",
      onClick: () => handlestudentsClick()

    },
    {
      label: "Passes",
      icon: <img src={image} alt="Dashboard" style={{width: "30px" }} />,
      link: "/passesadd",
      onClick: () => handlepassesaddClick()

    },
    {
      label: "Events",
      icon: <img src={event} alt="Dashboard" style={{ width: "30px" }} />,
      link: "/eventsadd",
      onClick: () => handleEventsClick()

    },
    {
      label: "Settings",
      link: "/settings",
      icon: <img src={settings} alt="Dashboard" style={{ width: "30px" }} />,

     // onClick: () => handleSettingsClick()

    },


  ]
  const history = useHistory()
  const handleLogout = () => {
    localStorage.setItem("isLoggedIn", false)

     window.location.reload()
     localStorage.clear();
     history.push('/');
     handleDrawerClose()
   };
  
  const handleDropdownClick = () => {
    setIsBillingDashboardOpen(!isBillingdashboardOpen)
    handleDrawerClose()
  }


  return (
    <List  >
      {

        superadmin.map((listItem, index) => (
          <React.Fragment key={index}>
            <ListItem button onClick={listItem.onClick} onMouseOver={() => console.log("Mouse over")}
              onMouseLeave={() => console.log("Mouse leave")}
                 style={{ backgroundColor: listItem.label === "United Grooves" ? "black" : "", color:  listItem.label === "United Grooves" ? "white" :""}}>
              <ListItemIcon>
                {listItem.icon}
              </ListItemIcon>
              <ListItemText primary={listItem.label} />
              {(listItem.label === "Billing Dashboard" && (isBillingdashboardOpen ? <ExpandLess /> : <ExpandMore/>) )}
            </ListItem>


            {listItem.subMenus && (
              <Collapse in={
                (listItem.label === "Dashboard" && isDashboardOpen) ||
                (listItem.label === "Billing Dashboard" && isBillingdashboardOpen) ||
                (listItem.label === "Offers" && isOffersOpen) ||
                (listItem.label === "Sales" && isInstructorsOpen) ||
                (listItem.label === "Stocks" && isStocksOpen) ||
                (listItem.label === "Reports" && isReportsOpen) ||
                (listItem.label === "Users" && isUserOpen) 
              } timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {listItem.subMenus.map((subMenu, subMenuIndex) => (
                    <ListItem button key={subMenuIndex} className={classes.nested} component={NavLink} to={subMenu.link} onClick={handleDrawerClose}>
                      <ListItemText primary={subMenu.label} />
                    </ListItem>
                  ))}


                </List>



              </Collapse>

            )}

          </React.Fragment>
        ))


      }

      {/* Logout button */}
      <ListItem button onClick={handleOpen }>
        <ListItemIcon>
          <img src={Logouticon} alt="Dashboard" style={{ fontFamily: 'Aclonica',width: "30px" }} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
      <Dialog className={classes.dialog} open={islogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle style={{fontFamily: 'Aclonica',textAlign:"center",color:"#6CBC44"}} id="form-dialog-title"> Log Out ?</DialogTitle>
        <DialogContent >
          <DialogContentText style={{fontFamily: 'Aclonica',color:"#000000"}}>
          Are you sure want to log out ?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
  <Grid container textAlign="center" spacing={2} style={{marginLeft:theme.spacing(4)}} >
    <Grid item style={{justifyContent:"center",textAlign:"center"}}>
      <Button onClick={handleClose} style={{fontFamily: 'Aclonica',background:"#FF0404",color:"white",width:"100px",borderRadius:"15px",textAlign:"center"}}>CANCEL</Button>
      </Grid>
    <Grid item style={{justifyContent:"center",textAlign:"center"}}>
      <Button onClick={handleLogout}  style={{fontFamily: 'Aclonica',background:"#328906",color:"white",width:"100px",borderRadius:"15px",textAlign:"center"}} autoFocus>
       LOG OUT
      </Button>
    </Grid>
  </Grid>
</DialogActions>

      </Dialog>
    </List>
  )
}
