import  React,{useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { PageHeader } from '../Common/CommonComponent';
import { Button, FormControl, Grid, InputLabel, inputLabelClasses, MenuItem, Select } from '@mui/material';
import BodyStyles from "./BodyStyles"
import axios from 'axios';
import api from "../api.json";
import { useHistory } from 'react-router-dom';
import theme from '../../theme';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme=>({
  select: {
    width: '220px',
    fontWeight: 600,
    fontSize: 16,
    color: '#590F72',
    [theme.breakpoints.down('sm')]: {
      width: '130px',
    },
  
   
    
    
  },
}));
export default function BlogComponent() {
  const classes = useStyles();
  const history=useHistory();
  const [sku, setSKU] = useState("");
  const [category, setCategory] = useState("")
  const [cate, setCate] = useState([])
  const [productname, setProductname] = useState("")
  const [status, setStatus] = useState("")
  const [buyingpriceinctax, setBuyingpriceinctax] = useState("")
  const [sellingpriceinc, setSellingpriceinc] = useState("")
  const [facevalue, setFacevalue] = useState("")
  const [disable, setDisable] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState({
    status: false,

    msg: "",
    type: ""
  })
  const [skuerror, setSkuError] = useState({
    status: false,

    msg: "",
    type: ""
  })
  useEffect(() => {
    axios
      .get(api.baseUrl + "/getcategory")
      .then((response) => {

        setCate(response.data);
        console.log("cate", cate)
        console.log("category", category)


      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);
  
  let handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);
    setIsActive(true);
    console.log("details--------->", {
      sku: sku,
      category: category,
      productname: productname,
      status: status,
      buyingpriceinctax: buyingpriceinctax,
      sellingpriceinc: sellingpriceinc,
      facevalue: facevalue

    })
    console.log("buying", buyingpriceinctax)
    console.log("selling", sellingpriceinc)
    {/*if (buyingpriceinctax >= sellingpriceinc) {
      console.log("in if")
      alert("selling price value should be greater than buying price")
    }
  else {*/}

    try {
      let res = await fetch(api.baseUrl + "/addproducts/", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          sku: sku,
          category: category,
          productname: productname,
          status: status,
          buyingpriceinctax: parseFloat(buyingpriceinctax).toFixed(2),
          sellingpriceinc: parseFloat(sellingpriceinc).toFixed(2),
          facevalue: parseFloat(facevalue).toFixed(2)

        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setDisable(false);
        setIsActive(false);
        setSKU("");
        setProductname("");
        setBuyingpriceinctax("");
        setCategory("");
        setFacevalue("");
        setSellingpriceinc("");

        setStatus("");
        setMessage("User created successfully");
       history.push("/notification")
      } else {
        //setMessage("Some error occured");
        if (resJson.message == "SKU already exist") {
          setSkuError({ status: true, message: resJson.message, type: 'error' })
          console.log("sku-->", resJson.message)

        } else {
          setError({ status: true, message: resJson.message, type: 'error' });
          console.log("skufggggg-->", resJson.message)
        }
      }
    } catch (err) {
      console.log(err);
      setDisable(false);
      setIsActive(false);
    }


  };
  const [age, setAge] = useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Grid container  display='flex' flexDirection="column" 
    alignItems='start'
    justifyContent='center'>
       <PageHeader  pageTitle='Add New Products' />
       <form onSubmit={handleSubmit}>
      <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        '& > :not(style)': { margin
          : 1},
      }}
    >
      <TextField
       
        id="demo-helper-text-aligned"
        label="Sku"
        value={sku}
        onChange={(e)=>setSKU(e.target.value)}
        InputLabelProps={{
          sx: {
            color: "#590F72",
            [`&.${inputLabelClasses.shrink}`]: {
              color: "#590F72",
              fontWeight:600,
              fontSize:16
            }
          }
        }}
      />
      <TextField
      
        id="demo-helper-text-aligned-no-helper"
        label="Product Name"
        value={productname}
        onChange={(e)=>setProductname(e.target.value)}
        InputLabelProps={{
          sx: {
            color: "#590F72",
            [`&.${inputLabelClasses.shrink}`]: {
              color: "#590F72",
              fontWeight:600,
              fontSize:16
            }
          }
        }}
      />
      
    </Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        
        '& > :not(style)': { m: 1 },
      }}
    >
    <FormControl>
    <InputLabel id="demo-simple-select-label"   InputLabelProps={{
          sx: {
            color: "#590F72",
            [`&.${inputLabelClasses.shrink}`]: {
              color: "#590F72",
              fontWeight:600,
              fontSize:16
            }
          }
        }}>Category</InputLabel>
         <Select
      id="select"
      label="Category"
      InputLabelProps={{
        sx: {
          color: "#590F72",
          [`&.${inputLabelClasses.shrink}`]: {
            color: "#590F72",
            fontWeight:600,
            fontSize:16
          }
        }
      }}
      value={category}
      onChange={(e) => setCategory(e.target.value)}
      className={classes.select}
    >
      {cate.map((cat) => (
        <MenuItem key={cat.value} value={cat.categoryname}>
          {cat.categoryname}
        </MenuItem>
      ))}
    </Select>
    </FormControl>
    <FormControl>
    <InputLabel id="demo-simple-select-label"   InputLabelProps={{
          sx: {
            color: "#590F72",
            [`&.${inputLabelClasses.shrink}`]: {
              color: "#590F72",
              fontWeight:600,
              fontSize:16
            }
          }
        }}>Status</InputLabel>
         <Select
      id="select"
      label="Status"
      
      InputLabelProps={{
        sx: {
          color: "#590F72",
          [`&.${inputLabelClasses.shrink}`]: {
            color: "#590F72",
            fontWeight:600,
            fontSize:16
          }
        }
      }}
      value={status}
            onChange={(e) => setStatus(e.target.value)}
      className={classes.select}
    >
    
        <MenuItem  value={10}>Active</MenuItem>
        <MenuItem  value={20}>In Active</MenuItem>
   
    </Select>
    </FormControl>


    
      
    </Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& > :not(style)': { m: 1},
      }}
    >
      <TextField
       
        id="outlined-Buying price inc.tax"
        label="Buying price inc.tax"
        value={buyingpriceinctax}
        onChange={(e)=>setBuyingpriceinctax(e.target.value)}
        InputLabelProps={{
          sx: {
            color: "#590F72",
            [`&.${inputLabelClasses.shrink}`]: {
              color: "#590F72",
              fontWeight:600,
              fontSize:16
            }
          }
        }}
      />
      <TextField
    
        id="demo-helper-text-aligned-no-helper"
        label="Selling price inc.Tax"
        value={sellingpriceinc}
        onChange={(e)=>setSellingpriceinc(e.target.value)}
        InputLabelProps={{
          sx: {
            color: "#590F72",
            [`&.${inputLabelClasses.shrink}`]: {
              color: "#590F72",
              fontWeight:600,
              fontSize:16
            }
          }
        }}
      />
      
    </Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& > :not(style)': { m: 1},
      }}
    >
      <TextField
      
        id="demo-helper-text-aligned"
        label="Face Value"
        value={facevalue}
        onChange={(e)=>setFacevalue(e.target.value)}
        InputLabelProps={{
          sx: {
            color: "#590F72",border:"#590F72",
            [`&.${inputLabelClasses.shrink}`]: {
              color: "#590F72",
              fontWeight:600,
              fontSize:16,border:"#590F72"
            }
          }
        }}
      />
         <Button type='submit' size='large' variant='contained'  marginLeft="2em" 
     color='primary' >Save</Button>
      
    </Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& > :not(style)': { m: 1 },
      }}
    >
     
    </Box>
    </form>
    </Grid>
  );
}