import { Button, FormControl, Grid, InputLabel, inputLabelClasses, NativeSelect,Select, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import api from "../../api.json";
import axios from "axios";
import "./Classes.css"
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useHistory } from 'react-router-dom';
import { createTheme,  makeStyles } from "@material-ui/core/styles";

const theme = createTheme();
const useStyles = makeStyles((theme) => ({
 option:{
marginBottom:theme.spacing(2)
  },
  textarea: {
    width: "820px",
    [theme.breakpoints.down("xs")]: {
      width: "220px",
    },
  }

}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  option:{
marginBottom:theme.spacing(2)
  },
  'label + &': {
    marginTop: theme.spacing(0),
    background: "white",
    marginLeft: theme.spacing(-2),
    width: "220px",

   
  },

  '& .MuiInputBase-input': {
    borderRadius: 4,
    
    position: 'relative',
    minHeight: 30,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Aclonica',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',

    },

  },

}));
const Classesadd = () => {
  const history = useHistory()
  const classes = useStyles();
  const [clsname, setClsName] = useState("");
  const [instructorname, setInstructorName] = useState("");
  const [description, setDescription] = useState("")
  const [style, setStyle] = useState("");
  const [email, setEmail] = useState("");
  const [clastype, setClstype] = useState("");
  const [atime,setatime] = useState("")
  const [day, setDay] = useState("")
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [instructoroptions,setinstructorOptions] = useState([])
  const [selectedInstructor,setSelectInstructor] = useState("")
  const [error,setError] = useState("")
  const [selectedTime, setSelectedTime] = useState(null);
  const [isAddSuccess,setisAddSuccess] = useState(false)
  const [editid,seteditid] = useState("")
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');

  let editDetails=[],setvar=null;
  const location = useLocation();

  if(location.state){
  console.log(location.state)
  const data = location.state;
  editDetails =data.state.editdata;
  setvar= data.state.isListEdit
  console.log("edit in add:", data.state.editdata)
  console.log("setvar:",setvar)
  }

  useEffect(() => {
    const gettime = (data)=>{
      const [start, end] = data.split('-');
      const formattedStartTime = formatTime(start);
      const formattedEndTime = formatTime(end);
    
      setStartTime(formattedStartTime);
      setEndTime(formattedEndTime);
      setatime(`${formattedStartTime} - ${formattedEndTime}`);
      console.log("starttime:", formattedStartTime, "end time:", formattedEndTime, "atime:", `${formattedStartTime} - ${formattedEndTime}`);
    }
    const formatTime = (time) => {
      const [hours, minutes] = time.split(':');
      const newval = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
      return newval.trim();
    }

    if(editDetails ){
      console.log("edit data in add:",editDetails);
      seteditid(editDetails._id)
      setClsName(editDetails.class_name)
      setSelectInstructor(editDetails.instructor_name)
      setInstructorName(editDetails.instructor_name)
      setClstype(editDetails.class_type)
      if(editDetails.time){
      gettime(editDetails.time)
      }
      setStyle(editDetails.style)
      setDay(editDetails.day)
      setDescription(editDetails.class_description)
     }
     
    
     if(isAddSuccess)
     {
      setClsName("")
      setInstructorName("")
      setClstype("")
      setStyle("")
      setatime("")
      setDay("")
      setDescription("")
       }
   
    fetch(api.baseUrl + "/api/admin/view_instructor_v2", {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const options = data.data.map((instructor) => ({
          label: instructor.instructor_name,
          value: instructor._id,
        }));
        console.log(options);
        setinstructorOptions(options);
       })
      .catch((error) => {
        console.error("Error fetching instructor data:", error);
      });
    }, []);

    const handleSelectInstructor = (selectedInstructor) => {
      console.log(instructoroptions)
      console.log("SelectedInstructor:",selectedInstructor)
      setSelectInstructor(selectedInstructor);
     // setinstructorid(selectedInstructor.value)
      setInstructorName(selectedInstructor)
         };
         

   const handleClassesEdit = async (e) => {
    console.log("Selected id:", editid);
    if (!clsname || !style || !instructorname || !clastype || !atime || !day || !description) {
      setError({ status: true, message: "Please Enter all Required Fields", type: 'error' });
      console.log("inside if")
      console.log("error",error)
      setTimeout(() => {
       setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
   try
   {
     let res = await fetch(api.baseUrl + "/api/admin/edit_classes/" + editid, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
          class_name: clsname,
          style: style,
          instructor_name: instructorname,
          class_type: clastype,
          time: atime,
          day: day,
          class_description: description
          }),
        });
        let resJson = await res.json();
        if (res.status === 200) {
          setMessage("Classes updated successfully");
          history.push("/classeslist")
          //window.location.reload();
        } else {
          setError({
            status: true,
            message: resJson.errors.msg,
            type: 'error'
        });
        setTimeout(() => {
            setError({ status: false, message: '', type: '' });
        }, 3000);
       return;
    }
  } catch (err) {
    console.log(err)
}
}

  const handleClassesCancel =()=>{
    history.push('/classeslist')
  }
  
  const combineTime = (start, end) => {
    if (start && end) {

      setatime(`${start} - ${end}`);
    }
  };
 
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const validatedInput = inputValue.replace(/[^a-zA-Z\s]/g, ''); // Allow letters only
    const maxLengthInput = validatedInput.slice(0, 15); // Restrict to 15 characters

    setClsName(maxLengthInput);
  };
  const handleStyleChange = (e) => {
    const inputValue = e.target.value;
    const validatedInput = inputValue.replace(/[^a-zA-Z\s]/g, ''); // Allow letters only
    const maxLengthInput = validatedInput.slice(0, 15); // Restrict to 15 characters

    setStyle(maxLengthInput);
  };
  const handleTypeChange = (e) => {
    const inputValue = e.target.value;
    const validatedInput = inputValue.replace(/[^a-zA-Z\s]/g, ''); // Allow letters only
    const maxLengthInput = validatedInput.slice(0, 15); // Restrict to 15 characters

    setClstype(maxLengthInput);
  };
  


  let handleClassesAdd = async (e) => {
    e.preventDefault();
    try {
      console.log("instructorname:",instructorname)
      if (!clsname || !style || !instructorname || !clastype || !atime || !day || !description) {
        setError({ status: true, message: "Please Enter all Required Fields", type: 'error' });
        console.log("inside if")
        console.log("error",error)
        setTimeout(() => {
         setError({ status: false, message: '', type: '' });
        }, 3000);
        return;
      }
      let res = await fetch(api.baseUrl + "/api/admin/add_classes", {
        method: "POST",
        headers: {
         "Content-Type": "application/json",
        },
        body: JSON.stringify({
          class_name: clsname,
          style: style,
          instructor_name: instructorname,
          class_type: clastype,
          time: atime,
          day: day,
          class_description: description
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setisAddSuccess(true)
        setMessage("Class created successfully");
        history.push("/classeslist")
        //window.location.reload();
      } else {
        setError({
          status: true,
          message: resJson.errors.msg,
          type: 'error'
      });
      setTimeout(() => {
          setError({ status: false, message: '', type: '' });
      }, 3000);
     return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAllclasses = () => {
    history.push("/classeslist")
  }
  



  return (
    <div>
                 {error && (
                 <div id='error'
                style={{  color: "red",textAlign: "start" }} >&nbsp;&nbsp;&nbsp;&nbsp;{error.message}</div> )}

       <h1 style={{
      fontFamily: 'Aclonica',
      fontStyle: 'normal', fontSize: "25px", marginTop: "50px"
    }}>CLASSES</h1>

      <div style={{ display: "flex", flexDirection: "row" }}>
      {setvar===null && (<Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>ADD CLASSES</Button>)}
      {setvar===true && (<Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>EDIT CLASSES</Button>)}
      {setvar===false && (<Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>VIEW CLASSES</Button>)}

        <Button onClick={handleAllclasses} style={{ background: "rgba(108, 188, 68, 0.5)", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>ALL CLASSES</Button>
      </div>
      <Grid container spacing={1} style={{ padding: '20px' }}>
        <Grid item xs={12} sm={6} >
          <Typography style={{ marginTop: theme.spacing(3), fontFamily: "Aclonica" }}>CLASS NAME<span style={{ color: 'red' }}>*</span></Typography>
          <TextField  disabled={setvar=== false}variant="outlined" value={clsname} onChange={handleNameChange} style={{
            marginBottom: theme.spacing(3),
            backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000"
          }} placeholder="Hip-hop" InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} />
          <Typography style={{ fontFamily: "Aclonica" }}>INSTRUCTOR NAME<span style={{ color: 'red' }}>*</span></Typography>
           <FormControl className={classes.error} disabled={setvar=== false} style={{
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1), minWidth: "250px", fontFamily: 'Aclonica'
          }}>
            <InputLabel id="demo-simple-select-label" sx={{ color: "#590F72", fontWeight: 500 }} InputLabelProps={{
              sx: {
                color: "#590F72",
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#590F72",
                  fontWeight: 500,
                  fontSize: 16,
                  fontFamily: 'Aclonica'
                }
              }
            }}></InputLabel>
            <NativeSelect id="select"

              placeholder='Select Instructor' InputProps={{
                style: {

                  fontFamily: "Aclonica",
                  fontWeight: 400
                }
              }} value={selectedInstructor} disabled={setvar=== false}
              onChange={(e) => handleSelectInstructor(e.target.value)}

              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    fontFamily: 'Aclonica',
                    fontWeight: 600,
                    fontSize: 16
                  }
                }
              }}

              className={classes.select}
              input={<BootstrapInput />}
            ><option value="" disabled>
            Select Instructor<span style={{ color: 'red' }}>*</span>
          </option>
              {instructoroptions.map((option) => (
    <option key={option.value} value={option.label}>
      {option.label}
    </option>
  ))}
            </NativeSelect>
          </FormControl>

          <Typography style={{ fontFamily: "Aclonica" }} >TIME<span style={{ color: 'red' }}>*</span></Typography>
          <input disabled={setvar=== false}
        onChange={(e)=>{setStartTime(e.target.value);  combineTime(e.target.value, endTime);}}
        value= {startTime}
        type="time"
        id="appt"
        name="appt"
        min="00:00"
        max="24:00"
        style={{
          marginBottom: theme.spacing(3), backgroundColor: "white", width: '16%',height: "10%",
          borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: "Aclonica"}}
        required
      />
      
      <span style={{ margin: '2px' }}> - </span>
      <input disabled={setvar=== false}
        onChange={(e)=>{setEndTime(e.target.value);  combineTime(startTime,e.target.value);}}
        type="time"
        value= {endTime}
        id="appt"
        name="appt"
        min="00:00"
        max="24:00"
        style={{
          marginBottom: theme.spacing(3), backgroundColor: "white", width: '16%',height: "10%",
          borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: "Aclonica"}}
        required
        
      />

        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ marginTop: theme.spacing(3), fontFamily: "Aclonica" }}>STYLE<span style={{ color: 'red' }}>*</span></Typography>
          <TextField placeholder="choreography"
          disabled={setvar=== false}
           InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={style} onChange={handleStyleChange} style={{ marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
          <Typography style={{ fontFamily: "Aclonica" }}>CLASS TYPE<span style={{ color: 'red' }}>*</span></Typography>
          <TextField disabled={setvar=== false}
          placeholder='studio' InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={clastype} onChange={handleTypeChange} style={{ marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
          <Typography style={{ fontFamily: "Aclonica" }}>DAY<span style={{ color: 'red' }}>*</span></Typography>

          <FormControl className={classes.error} disabled={setvar=== false} style={{
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1), minWidth: "250px", fontFamily: 'Aclonica'
          }}>
            <InputLabel id="demo-simple-select-label" sx={{ color: "#590F72", fontWeight: 500 }} InputLabelProps={{
              sx: {
                color: "#590F72",
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#590F72",
                  fontWeight: 500,
                  fontSize: 16,
                  fontFamily: 'Aclonica'
                }
              }
            }}></InputLabel>
            <NativeSelect id="select"

              placeholder='wednesday' InputProps={{
                style: {

                  fontFamily: "Aclonica",
                  fontWeight: 400
                }
              }} value={day}disabled={setvar=== false} onChange={(e) => setDay(e.target.value)}
              InputLabelProps={{
                sx: {



                  [`&.${inputLabelClasses.shrink}`]: {
                    fontFamily: 'Aclonica',
                    fontWeight: 600,
                    fontSize: 16
                  }
                }
              }}

              className={classes.select}
              input={<BootstrapInput />}
            >
              <option value="" style={{ fontFamily: 'Aclonica', background: "#6CBC44" }}></option>
              <option value="Monday" style={{ fontFamily: 'Aclonica', background: "#6CBC44" }} >Monday</option>
              <option style={{height: '0px'}}></option>
              <option value="Tuesday"style={{ fontFamily: 'Aclonica', background: "#6CBC44" }} >Tuesday</option>
               <option style={{height: '0px'}}></option>
              <option value="Wednesday" style={{ fontFamily: 'Aclonica', background: "#6CBC44" }}>wednesday</option>
               <option style={{height: '0px'}}></option>
              <option value="Thursday" style={{ fontFamily: 'Aclonica', background: "#6CBC44" }}>Thursday</option>
               <option style={{height: '0px'}}></option>
              <option value="Friday"style={{ fontFamily: 'Aclonica', background: "#6CBC44" }} >Friday</option>
               <option style={{height: '0px'}}></option>
              <option value="Saturday" style={{ fontFamily: 'Aclonica', background: "#6CBC44" }}>Saturday</option>
                           </NativeSelect>
          </FormControl>

        </Grid>
        <div>
          <Typography style={{ marginLeft: theme.spacing(1), fontFamily: "Aclonica" }}>CLASS DESCRIPTION<span style={{ color: 'red' }}>*</span></Typography>
          <textarea className={classes.textarea}disabled={setvar=== false} value={description} onChange={(e) => setDescription(e.target.value)} style={{

            marginLeft: theme.spacing(1),
            minHeight: "100px",
            maxHeight: "auto", fontFamily: "Aclonica", marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000"
          }} />
        </div>
      </Grid>

      <div style={{ textAlign: "center" }}>
        <Button type="submit" onClick={handleClassesCancel} style={{ fontFamily: "Aclonica", backgroundColor: "#FF0404", width: "120px", height: "40px", color: "white", borderRadius: "20px" }}>CANCEL</Button>
     { setvar===true && (<Button type='submit' onClick={handleClassesEdit} style={{ fontFamily: "Aclonica", backgroundColor: "#328906", width: "120px", height: "40px", color: "white", borderRadius: "20px", marginLeft: theme.spacing(2) }}>UPDATE</Button>)}
     { setvar===null && (<Button type='submit' onClick={handleClassesAdd} style={{ fontFamily: "Aclonica", backgroundColor: "#328906", width: "120px", height: "40px", color: "white", borderRadius: "20px", marginLeft: theme.spacing(2) }}>ADD</Button>)}
      </div>
    </div>
  )
}

export default Classesadd