import React,{useState,useEffect} from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import axios from 'axios';
import api from "../../api.json"
import { Button, Grid, IconButton, Paper, TextField } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import editicon from "../assets/editicon.png";
import deleteicon from "../assets/deleteicon.png";
//import "./Students.css";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../theme';
import SearchIcon from '@mui/icons-material/Search';
const useStyles = makeStyles({
  dialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent', // set background color here
    width:"50px",
    height:"50px"
    },
  },
});
const Offerslist = () => {
    const history = useHistory()
    const classes = useStyles();
    const [data,setData]=useState([])
    const getoffersdetails = (e) => {
        axios
          .get(api.baseUrl + "/api/admin/view_offers")
          .then((response) => {
    
            setData(response.data.data.reverse());
           console.log("result123-->",response.data.data)
          })
          .catch((err) => {
            console.log("error", err);
          });
    
      }
      const [open, setOpen] = React.useState(false);

    
      const handleClose = () => {
        setOpen(false);
      };
      const[contact,setContact]=useState([])
    
      const [selectedId, setSelectedId] = useState(null);

      const handleClickOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
      };
      
      const handleDeleteoffer = () => {
        axios
          .delete(api.baseUrl + "/api/admin/delete_offers/" + selectedId)
          .then((response) => {
            getoffersdetails();
            console.log("deleted")
          })
          .catch((err) => {
            console.log("error", err);
          });
        handleClose();
      };
      //search bar api
  const requestSearch = (searchedVal) => {
    console.log("searchedVal", searchedVal)
    axios
      .get( api.baseUrl + "/api/admin/searchClasses/" + searchedVal)
      .then((response) => {
        setContact(response.data.data);
        setData(response.data.data);
        console.log("topup", response.data)
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  
      useEffect(() => {
       
        getoffersdetails()
      }, []);
      const handleAddstudent =() =>{
        history.push("/classesadd")
      }
      const handleEdit = (id) => {
        // Handle edit action for row with the given id
      };
      
      const handleDelete = (id) => {
        // Handle delete action for row with the given id
      };
      
      const handleButton = () => {
       history.push("/offersadd")
      };
      const handleAllclasses =() =>{
        history.push("/classeslist")
      }
      const handleAddclasses =() =>{
        history.push("/classesadd")
      }
      const handleEdit_products = (data) => {
        console.log("edit")
        let { _id, offer_name,offer_code,validity_from,validity_till,dicount,applicable_passes } = data;
        localStorage.setItem('ID', _id);
        localStorage.setItem('offername', offer_name);
        localStorage.setItem('offercode', offer_code);
        localStorage.setItem('validityfrom',validity_from);
        localStorage.setItem('validitytill', validity_till);
        localStorage.setItem('discount', dicount);
        localStorage.setItem('applicablepasses', applicable_passes);
     
        history.push('/editoffers')
      }
      const handleAddoffers=()=>{
        history.push("/offersadd")
      }
      const handleEdit_products1 = (data) => {
        console.log("edit")
        let { _id, offer_name,offer_code,validity_from,validity_till,dicount,applicable_passes } = data;
        localStorage.setItem('ID', _id);
        localStorage.setItem('offername', offer_name);
        localStorage.setItem('offercode', offer_code);
        localStorage.setItem('validityfrom',validity_from);
        localStorage.setItem('validitytill', validity_till);
        localStorage.setItem('discount', dicount);
        localStorage.setItem('applicablepasses', applicable_passes);
     
        history.push('/edit_offers')
      }
      
  return (
    <div>
        <h1 style={{
            fontFamily: 'Aclonica',
            fontStyle: 'normal', fontSize: "25px", marginTop: "50px"
        }}>OFFERS</h1>
       
            <div style={{ display: "flex", flexDirection: "row",marginTop:theme.spacing(10) }}>
                <Button onClick={handleAddoffers} 
               style={{ background: "rgba(108, 188, 68, 0.5)", color: "#FFFFFF", width: 650, fontWeight: 600, fontFamily: 'Aclonica', 
               height: "25px" }}>ADD OFFERS</Button>
                <Button  style={{ background: " #6CBC44", color: "#FFFFFF", width: 650, fontWeight: 600, fontFamily: 'Aclonica',
                 height: "25px" }}>ALL OFFERS</Button>
            </div>
            <Grid container spacing={2}>
  <Grid item xs={12}>
 
  <TableContainer style={{maxWidth:"100%", overflowX:"auto"}}>
      <Table style={{minWidth:"650px"}}>
    <TableHead>
      <TableRow>
        <TableCell style={{fontFamily:'Aclonica'}}>OFFER NAME</TableCell>
        <TableCell style={{fontFamily:'Aclonica'}}>OFFER CODE</TableCell>
        <TableCell style={{fontFamily:'Aclonica'}}>VALIDITY</TableCell>
        <TableCell style={{fontFamily:'Aclonica'}}>DISCOUNTED PRICE</TableCell>
        <TableCell style={{fontFamily:'Aclonica'}}>APPLICABLE PASSES</TableCell>
        
      </TableRow>
    </TableHead>
    <TableBody>
      {data.map((row, index) => (
        <TableRow key={row.id} style={{ backgroundColor: index % 2 === 0 ? 'rgba(108, 188, 68, 0.5)' : 'white' }}  sx={{
            "& td": {

            
              alignItems: "center", height: "2px !important",padding: "2px !important"
            }
          }}
>
          <TableCell>{row.offer_name}</TableCell>
          <TableCell>{row.offer_code}</TableCell>
          <TableCell>{row.validity_from} - {row.validity_till}</TableCell>
          <TableCell>{row.dicount}</TableCell>
          <TableCell>{row.applicable_passes}</TableCell>
         
          
          <TableCell style={{width:"130px"}}>   <Button   onClick={() => handleEdit_products(row)}>
          <img src={editicon}  style={{width:"30px"}}/>
            </Button>
            <Button onClick={() => handleClickOpen(row._id)}>
            <img src={deleteicon}  style={{width:"30px"}}/>
            </Button> 
            <Dialog className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"> Delete Offers ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure want to delete ?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
  <Grid container textAlign="center" spacing={2} style={{marginLeft:theme.spacing(2)}} >
    <Grid item style={{justifyContent:"center"}}>
      <Button onClick={handleClose} style={{background:"#82C341",color:"white",width:"80px",borderRadius:"15px",textAlign:"center"}}>CANCEL</Button>
    </Grid>
    <Grid item>
      <Button onClick={handleDeleteoffer}  style={{background:"#DC1E1B",color:"white",width:"80px",borderRadius:"15px",textAlign:"center"}} autoFocus>
       DELETE
      </Button>
    </Grid>
  </Grid>
</DialogActions>

      </Dialog>
      </TableCell>
            <TableCell><Button style={{color:'orange',fontFamily: 'Aclonica',width:"150px"}} onClick={() => handleEdit_products1(row)} >View More 
            <ChevronRightIcon style={{marginTop:theme.spacing(-0.4),fontWeight:600}}/> </Button></TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

</Grid>
</Grid>

    </div>
  )
}





export default Offerslist