import React from "react";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Profile from "./Navtabs/profile";
import Notification from "./Navtabs/notification";
import { useStyles } from "./HeaderStyles";
import Messages from "./Navtabs/Messages";
import MenuIcon from "@material-ui/icons/Menu";

export default function Navbar({ handleDrawerOpen }) {
  const classes = useStyles();

  return (
    <AppBar position='fixed' sx={{backgroundImage:" linear-gradient(to right, #330537, #6e0845, #a62144, #d24c35, #eb8112);"}}>
        <Hidden mdUp>
      <Toolbar className={classes.toolbar}>
        <Typography variant='h6' className={classes.logo}>
          {"United Grooves"}
        </Typography>
        
      
          <IconButton color='inherit' onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
       
      </Toolbar>
      </Hidden>
    </AppBar>
  );
}
