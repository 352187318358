import { makeStyles } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { blue,red } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    background:"#FFF",
    color:"#000000"
  },
 
  logo: {
    color: "#6CBC44",
    textAlign:"center",
    fontSize:"20px",
    fontFamily:"Aclonica"
  },
  navlist: {
    minWidth: "250px",
    maxWidth: "300px",
  },
  ulAvater: {
    backgroundColor: blue["A200"],
    color: "white",
    display:"none"
  },
  navAvatar: {
    width: "35px",
    height: "35px",
  },

  //wrapper of main contianer
  wrapper: {
    minHeight: "100vh",
    height: "auto",
    background: "rgba(108, 188, 68, 0.3) ",
    marginTop: "0px",
    padding: theme.spacing(2, 2, 0, 34),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2),
      marginTop: "0px",
    },
  },

  //Side nav
  drawerPaper: {
    width: "250px",
  
   
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      color:"#590F72"
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: "#590F72",
    "& :hover , &:hover div": {
      color: blue["A200"],
    },
    " & div": {
      color: blueGrey["A400"],
    },
  },
  navlinks: {
    color: "#590F72",
    "& :hover , &:hover div": {
      color: blue["A200"],
    },
    " & div": {
      color: blueGrey["A400"],
    },
  },
  activeNavlinks: {
    color: blue["A700"],
    "& div": {
      color: blue["A700"],
    },
  },
  navButton: {
    width: " 100%",
    textTransform: "capitalize",
  },
}));
