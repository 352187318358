import { Button, Grid, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
//import theme from '../../../theme'
import api from "../../api.json";
import axios from "axios";
import "./Students.css"
import { useHistory } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dateicon from "../assets/date.png"
import { format } from 'date-fns';
import { createTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
const theme = createTheme();
const useStyles = makeStyles((theme) => ({


    root: {
        marginTop: '10px',
        fontWeight: 400,
        fontFamily: 'Aclonica'
    },
    textarea: {
        width: "240px",
        [theme.breakpoints.down("xs")]: {
            width: "220px",
        },
    }

}));
const Editstudent = () => {
    const history = useHistory()
    const classes = useStyles();
    const [name, setName] = useState("");
    const [contact, setContact] = useState("");
    const [additionalcontact, setAdditionalcontact] = useState("")
    const [dob, setDob] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [data, setData] = useState([]);
    const [id, setID] = useState(null);
    const [message, setMessage] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    let handleStudentAdd = async (e) => {
        e.preventDefault();


        try {


            let res = await fetch(api.baseUrl + "/api/admin/add_student", {
                method: "POST",

                headers: {
                    "Content-Type": "application/json",


                },
                body: JSON.stringify({

                    student_name: name,
                    email: email,
                    contact_number: contact,
                    address: address,
                    additional_contact: additionalcontact,
                    date_of_birth: dob

                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {

                setMessage("User created successfully");
                history.push("/dashboard")
                //window.location.reload();
            } else {
                setMessage("Some error occured");
            }
        } catch (err) {
            console.log(err);

        }
    };
    useEffect(() => {
        setID(localStorage.getItem('ID'))
        setEmail(localStorage.getItem("email"))
        setName(localStorage.getItem("studentname"))
        setContact(localStorage.getItem("contactnumber"))
        setAddress(localStorage.getItem('adss'));
        setAdditionalcontact(localStorage.getItem('adc'));
        setSelectedDate(localStorage.getItem('dob1'));
    }, []);
    let handleClick = async (e) => {
        e.preventDefault();



        try {

            let res = await fetch(api.baseUrl + "/api/admin/edit_student/" + id, {
                method: "PUT",

                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({

                    student_name: name,
                    email: email,
                    contact_number: contact,
                    address: address,
                    additional_contact: additionalcontact,
                    date_of_birth: selectedDate
                }),
            });



            let resJson = await res.json();
            if (res.status === 200) {

                setMessage("User created successfully");
                history.push("/studentslist")
            } else {
                setMessage("Some error occured");
            }
        } catch (err) {
            console.log(err);


        }
    };
    const handleAddstudent = () => {
        history.push("/studentsadd")
    }
    const handleAllstudent = () => {
        history.push("/studentslist")
    }

    return (
        <div> <h1 style={{
            fontFamily: 'Aclonica',
            fontStyle: 'normal', fontSize: "25px", marginTop: "50px"
        }}>STUDENTS</h1>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }} >ADD STUDENT</Button>
                <Button onClick={handleAllstudent} style={{ background: "rgba(108, 188, 68, 0.5)", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>ALL STUDENTS</Button>
            </div>
            <Grid container spacing={1} style={{ padding: '20px' }}>
                <Grid item xs={12} sm={6} >
                    <Typography style={{ marginTop: theme.spacing(3), fontFamily: 'Aclonica' }}>Name</Typography>
                    <TextField variant="outlined" value={name} onChange={(e) => setName(e.target.value)} style={{
                        marginBottom: theme.spacing(3), fontFamily: 'Aclonica',
                        backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000"
                    }} placeholder="Karan" InputProps={{
                        style: {

                            fontFamily: "Aclonica",
                            fontWeight: 400
                        }
                    }} />
                    <Typography style={{ fontFamily: 'Aclonica' }}>Contact</Typography>
                    <TextField placeholder='+91 8123456789' InputProps={{
                        style: {

                            fontFamily: "Aclonica",
                            fontWeight: 400
                        }
                    }} value={contact} onChange={(e) => setContact(e.target.value)} onKeyPress={(e) => {
                        const allowedKeys = /[0-9]/;
                        const key = e.key;
                        if (!allowedKeys.test(key)) {
                          e.preventDefault();
                        }
                      }} 
                        style={{ marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: 'Aclonica' }} />
                    <Typography style={{ fontFamily: 'Aclonica' }}>Additional Contact</Typography>
                    <TextField placeholder='+91 9874563219' InputProps={{
                        style: {
                            fontFamily: "Aclonica",
                            fontWeight: 400
                        }
                    }} value={additionalcontact} onChange={(e) => setAdditionalcontact(e.target.value)}
                    onKeyPress={(e) => {
                        const allowedKeys = /[0-9]/;
                        const key = e.key;
                        if (!allowedKeys.test(key)) {
                          e.preventDefault();
                        }
                      }}  style={{ fontFamily: 'Aclonica', marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
                    <Typography style={{ fontFamily: 'Aclonica' }}>Dob</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker
                            renderInput={(props) => <TextField {...props} readOnly placeholder='02/12/2001'
                                style={{
                                    fontFamily: "Aclonica", marginBottom: theme.spacing(3), backgroundColor: "white",
                                    borderColor: "white", borderRadius: "5px", width: "220px"
                                }} />}
                            value={selectedDate}
                            onChange={(date) => handleDateChange(date)}
                            disableMaskedInput
                            onBeforeInput={(e) => {
                                e.preventDefault();
                            }}

                            style={{
                                fontFamily: "Aclonica", marginBottom: theme.spacing(3),
                                 backgroundColor: "white",
                                borderColor: "white", borderRadius: "5px", color: "#000000"
                            }}
                            inputProps={{ disabled: true }}
                        />
                    </LocalizationProvider> </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography style={{ marginTop: theme.spacing(3), fontFamily: 'Aclonica' }}>Email</Typography>
                    <TextField placeholder="karan@gmail.com" InputProps={{
                        style: {

                            fontFamily: "Aclonica",
                            fontWeight: 400
                        }
                    }} value={email} onChange={(e) => setEmail(e.target.value)} style={{ fontFamily: 'Aclonica', marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
                    <Typography style={{ fontFamily: 'Aclonica' }}>Address</Typography>
                    <textarea className={classes.textarea} placeholder='56, 4th cross Rd, Annanagar' InputProps={{
                        style: {

                            fontFamily: "Aclonica",
                            fontWeight: 400
                        }
                    }} value={address} onChange={(e) => setAddress(e.target.value)} style={{


                        minHeight: "100px",
                        maxHeight: "auto", marginBottom: theme.spacing(3), fontFamily: "Aclonica", backgroundColor: "white", borderColor: "white",
                        borderRadius: "5px"
                    }} /> </Grid>
            </Grid>

            <div style={{ textAlign: "center" }}>
                <Button type="submit" onClick={handleAllstudent} style={{ backgroundColor: "#FF0404", width: "120px", fontFamily: 'Aclonica', height: "40px", color: "white", borderRadius: "20px" }}>CANCEL</Button>
                <Button type='submit' onClick={handleClick}
                 style={{ backgroundColor: "#328906", fontFamily: 'Aclonica', width: "120px", height: "40px", color: "white",
                  borderRadius: "20px", marginLeft: theme.spacing(2) }}>UPDATE</Button>
            </div>
        </div>
    )
}



export default Editstudent;