import { makeStyles } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { red } from "@mui/material/colors";
export const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
  
  },

  typography:{
  
    fontWeight: 500,
    fontSize: 18,
    color: '#590F72',
    padding:"10px",
   alignContent:"center",
   justifyContent:"center"


  },
  select: {
    width: '250px',
    fontWeight: 600,
    fontSize: 16,
    color: '#590F72',
  },
  root: {
    "& .MuiTableCell-head": {
        color: "white",
        backgroundColor: "#CAADD5"
    },
    "& .MuiFormControl-root":{
      width:"80%",
      margin:theme.spacing(1)
    }
},

palette:{
  primary:{
      main:"#CAADD5",
      light: '#CAADD5',
      backgroundColor: "#CAADD5",
      dark: '#CAADD5',
      contrastText: '#590F72',
  },

  
},
  responsiveImg: {
    width: " 100%",
    height: "auto",
  },
  cardImage: {
    maxHeight: "150px",
    overflowY: "hidden",
  },
  
  //page
  pageTitle: {
    color: "#590F72",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  
    textTransform: "capitalize",

  },
  pageSubTitle: {
    color: "#590F72",
    margin: theme.spacing(1, 0),
    textTransform: "uppercase",
  },

  //dashboard
  cardLabel: {
    textTransform: "uppercase",
    color: blueGrey[500],
    textAlign: "center",
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
  },
  cardTitle: {
    textTransform: "capitalize",
    color: blueGrey[800],
    textAlign: "center",
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.8rem",
    },
  },
  ratioBtn: { fontSize: "1rem", fontWeight: "bold" },
  cardContent: {
    position: "relative",
  },
  //cardGraph
  displayCardGraph: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100% !important",
    height: "45% !important",
  },

  //user trafic graph
  displayUserGraph: {
    width: "100%",
    minHeight: "300px",
    height: "auto",
  },
  footer: {
    background: "#feffee",
    padding: theme.spacing(0, 0, 0, 33),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 1),
      marginTop: "45px",
    },
  },
  root1: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor:'lightgrey',
  },
  selectEmpty: {
   
  }
}));
