import { Button, Grid, InputAdornment, TextField,Typography,Checkbox,Select,MenuItem} from '@mui/material';
import Radio from '@mui/material/Radio';
import ClearIcon from '@mui/icons-material/Clear';

import { useLocation } from 'react-router-dom';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import React, { useState, useEffect } from 'react'
import api from "../../api.json";
import axios from "axios";
import upload from "../assets/upload.png";
import image212 from "../assets/imageplus.png"
import { useHistory } from 'react-router-dom';
import { createTheme, makeStyles } from "@material-ui/core/styles";
import "./Instructor.css"

const theme = createTheme();
const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none'
  },
  textarea: {
    width: "820px",
    [theme.breakpoints.down("xs")]: {
      width: "220px",
    },
  },
  formControl: {
    marginBottom: theme.spacing(3),
    backgroundColor: 'white',
    borderColor: 'white',
    borderRadius: '5px',
    width: '100%', // Adjust the width as needed
  },
}));
const Instructorsadd = () => {
  
  const history = useHistory()
  const classes = useStyles();
  const [name, setName] = useState("");
  const [contactno, setContactno] = useState("");
  const [classtype, setClasstype] = useState("");
  const [availableday, setAvailableday] = useState([]);
  const [uploadid, setUploadid] = useState("");
  const [instructorid, setInstructorid] = useState("");
  const [aboutinstructor, setAboutinstructor] = useState("");
  const [instructortype, setInstructortype] = useState("");
  const [uploadimage, setUploadimage] = useState("");
  const [chooseimage, setChooseimage] = useState("");
  const [email, setEmail] = useState("");
  const [atime,setatime] = useState("")
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');
  const [address, setAddress] = useState("");
  const [classstyle, setClassstyle] = useState("");
  const [instagramid, setInstagramid] = useState("");
  const [message, setMessage] = useState("");
  const [error,setError] = useState("")
  const [value, setValue] = React.useState('');
  const [isAddSuccess,setisAddSuccess] = useState(false)
  const [editid,seteditid] = useState("")
  let editDetails=[],setvar=null;
    
  const location = useLocation();
  if(location.state){
  console.log(location.state)
  const data = location.state;
 editDetails =data.state.editdata;
setvar= data.state.isListEdit
console.log("edit in add:", data.state.editdata)
console.log("setvar:",setvar)

  }
 

  useEffect(() => {
    
    const gettime = (data)=>{
      const [start, end] = data[0].split('-');
      const formattedStartTime = formatTime(start);
      const formattedEndTime = formatTime(end);
    
      setStartTime(formattedStartTime);
      setEndTime(formattedEndTime);
      setatime(`${formattedStartTime} - ${formattedEndTime}`);
      console.log("starttime:", formattedStartTime, "end time:", formattedEndTime, "atime:", `${formattedStartTime} - ${formattedEndTime}`);
    }
    const formatTime = (time) => {
      const [hours, minutes] = time.split(':');
      const newval = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
      return newval.trim();
    }

   if(editDetails){
    console.log("edit data in add:",editDetails);
    seteditid(editDetails._id)
    setName(editDetails.instructor_name)
    setEmail(editDetails.email)
    setContactno(editDetails.contact_number)
    setClasstype(editDetails.class_type)
    setClassstyle(editDetails.class_style)
    setInstructorid(editDetails.instructor_id)
    setInstructortype(editDetails.instructor_type)
    setValue(editDetails.instructor_type)
    setAddress(editDetails.address)
    setAvailableday(editDetails.availability_day)
    if(editDetails.availability_day){
    setSelectedOptions(editDetails.availability_day)}
    setUploadimage(editDetails.upload_image)
    setFileName(editDetails.upload_image)
    setChooseimage(editDetails.choose_profile_image)
    //setSelectedImages(editDetails.choose_profile_image)
    setUploadid(editDetails.upload_id)
    setInstagramid(editDetails.instagram_id)
    setAboutinstructor(editDetails.about)
    if(editDetails.availability_time){
      gettime(editDetails.availability_time)
      }
    
   }
   

  
   
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [selectedOptions, setSelectedOptions] = useState(["Select days"]);
  const options = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday'];

  const handleOptionToggle = (option) => () => {
    if (selectedOptions.includes('Select days')) {
      setSelectedOptions([option]);
    } else {
      const currentIndex = selectedOptions.indexOf(option);
      const newSelectedOptions = [...selectedOptions];

      if (currentIndex === -1) {
        newSelectedOptions.push(option);
      } else {
        newSelectedOptions.splice(currentIndex, 1);
      }

      setSelectedOptions(newSelectedOptions);
    }
  };
const handleInstructorEdit = async (e) => {
  console.log("Selected id:", editid);
    if (!instructorid || !name || !email || !contactno || !address || !classtype || !classstyle || !uploadid || !value || !imageUrl || !selectedImages || !selectedOptions || !atime) {
   setError({ status: true, message: "Please Enter all Required Fields", type: 'error' });
   console.log("inside if")
   console.log("error",error)
   setTimeout(() => {
    setError({ status: false, message: '', type: '' });
   }, 3000);
   return;
 }
    const emailPattern = /^[A-Za-z0-9]+([._%-]*[A-Za-z0-9]+)*@[A-Za-z0-9]+([.-]*[A-Za-z0-9]+)*\.[A-Za-z]{2,4}$/;

if (!emailPattern.test(email)) {
  setError({ status: true, message: "Please Enter valid email address", type: 'error' });
  console.log("error",error)
   setTimeout(() => {
    setError({ status: false, message: '', type: '' });
   }, 3000);
   return;
 }
 if (contactno.length<10) {
  setError({ status: true, message: "Please Enter valid Contact Number", type: 'error' });
  console.log("error",error)
   setTimeout(() => {
    setError({ status: false, message: '', type: '' });
   }, 3000);
   return;
 }
 try
 {
   let res = await fetch(api.baseUrl + "/api/admin/edit_instructor_v2/" + editid, {
        method: "PUT",

        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({

          instructor_id: instructorid,
          instructor_name: name,
          email: email,
          contact_number: contactno,
          address: address,
          class_type: classtype,
          class_style: classstyle,
          upload_id: uploadid,
          about: aboutinstructor,
          instructor_type: value,
          upload_image: fileName,
          choose_profile_image: selectedImages,
          instagram_id: instagramid,
          availability_day: selectedOptions,
          availability_time: atime

        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setMessage("User updated successfully");
        history.push("/instructorlist")
        //window.location.reload();
      } else {
        setError({
          status: true,
          message: resJson.errors.msg,
          type: 'error'
      });
      setTimeout(() => {
          setError({ status: false, message: '', type: '' });
      }, 3000);
     return;
  }
} catch (err) {
 
 }

}

const handleInstructorCancel =()=>{
  history.push('/instructorlist')
}
  let handleInstructorAdd = async (e) => {
    e.preventDefault();

    console.log("Selected value:", value);
    if (!instructorid || !name || !email || !contactno || !address || !classtype || !classstyle || !uploadid || !value || !imageUrl || !selectedImages || !selectedOptions || !atime) {
   setError({ status: true, message: "Please Enter all Required Fields", type: 'error' });
   console.log("inside if")
   console.log("error",error)
   setTimeout(() => {
    setError({ status: false, message: '', type: '' });
   }, 3000);
   return;
 }
 const emailPattern = /^[A-Za-z0-9]+([._%-]*[A-Za-z0-9]+)*@[A-Za-z0-9]+([.-]*[A-Za-z0-9]+)*\.[A-Za-z]{2,4}$/;

if (!emailPattern.test(email)) {
  setError({ status: true, message: "Please Enter valid email address", type: 'error' });
  console.log("error",error)
   setTimeout(() => {
    setError({ status: false, message: '', type: '' });
   }, 3000);
   return;
 }
 if (contactno.length<10) {
  setError({ status: true, message: "Please Enter valid Contact Number", type: 'error' });
  console.log("error",error)
   setTimeout(() => {
    setError({ status: false, message: '', type: '' });
   }, 3000);
   return;
 }


    try {
      console.log(
        {

          instructor_id: instructorid,
          instructor_name: name,
          email: email,
          contact_number: contactno,
          address: address,
          class_type: classtype,
          class_style: classstyle,
          upload_id: uploadid,
          about: aboutinstructor,
          instructor_type: value,
          upload_image: imageUrl,
          choose_profile_image: selectedImages,
          instagram_id: instagramid,
          availability_day: availableday,
          availability_time: atime


        })

      let res = await fetch(api.baseUrl + "/api/admin/add_instructor_v2", {
        method: "POST",

        headers: {
          "Content-Type": "application/json",


        },
        body: JSON.stringify({

          instructor_id: instructorid,
          instructor_name: name,
          email: email,
          contact_number: contactno,
          address: address,
          class_type: classtype,
          class_style: classstyle,
          upload_id: uploadid,
          about: aboutinstructor,
          instructor_type: value,
          upload_image: fileName,
          choose_profile_image: selectedImages,
          instagram_id: instagramid,
          availability_day: selectedOptions,
          availability_time: atime

        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {

        setMessage("User created successfully");
        setisAddSuccess(true)
        history.push("/instructorlist")
        //window.location.reload();
      } else {
        setError({
          status: true,
          message: resJson.errors.msg,
          type: 'error'
      });
      setTimeout(() => {
          setError({ status: false, message: '', type: '' });
      }, 3000);
     return;
  }
} catch (err) {
  console.log(err);
}
}
  const [imageUrl1, setImageUrl1] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [profileImageName, setProfileImageName] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [imageerror,setimageerror] = useState(false)

  const handleIdUpload = (event) => {

    const file = event.target.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
    const reader = new FileReader();

    reader.onload = () => {
      setImageUrl1(reader.result);
    };

    reader.readAsDataURL(file);
    setUploadid(file.name);
  } else {
    setimageerror(true)
    console.log('Invalid file type. Please select a JPEG or PNG file.');
    setTimeout(() => {
      setimageerror("");
  }, 3000);
 return;
  } 
  };
  const handleImageUpload = (event) => {

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImageUrl(reader.result);
    };

    reader.readAsDataURL(file);

    setFileName(file.name); // Set the file name
  };
  const [filePathGST, setFilePathGST] = useState({});
  const [filePath1, setFilePath1] = useState({});
  const chooseFileapi= (type) => {
  
  
       fetch(api.baseUrl +  "/api/user/uploadFile", {
         method: "POST",
         headers: new Headers({
           "Content-Type": "application/x-www-form-urlencoded", 
         }),
         body:JSON.stringify({
          file_url:profileImageUrl
          }) ,
       })
         .then((data) => data.json())
         .then((res) => {
           console.log("upload succes==>", res.file_url);
           setSelectedImages(res.file_url);
         })
         .catch((error) => {
           console.log("upload error", error);
         });
       //
 

   }
   const uploadImages = async () => {
    const formData = new FormData();
    selectedImages.forEach((image) => {
      formData.append("file", image);
    });
  
    try {
      const response = await axios.post(api.baseUrl + "/api/user/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("hi");
      console.log("Upload success: ", response);
    } catch (error) {
      console.error("Upload failed: ", error);
    }
  };
  
  const handleUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setProfileImageUrl(reader.result);
    };

    reader.readAsDataURL(file);

    // Set the file name
  };

  //id no
  {/* const [idNo, setIdNo] = useState('');

  const handleIdNoChange = (event) => {
    setIdNo(event.target.value);
  };

  const handlefileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setIdNo(reader.result);
    };

    reader.readAsDataURL(file);
  };*/}
  const handleAllinstructor = () => {
    history.push("/instructorlist")
  }
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploaderror,setuploaderror] = useState(false)

  const onSelectFile = (event) => {
    
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    console.log("inside selectfile",selectedFiles)
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);

    });
  
    // Check if adding new images exceeds the limit
    if (selectedImages.length + imagesArray.length > 3) {
      setuploaderror(true)
      setTimeout(() => {
        setuploaderror("");
    }, 3000);
      return;
    }

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };
  const handleRemoveImage = (index) => {
    console.log("inside remove")
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    const newImages = [...updatedImages]
    setSelectedImages(newImages);
  };
  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }
  const handleInstructorIdChange = (e) => {
    const inputValue = e.target.value;
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;

    if (inputValue.length <= 10 && alphanumericRegex.test(inputValue)) {
      setInstructorid(inputValue);
    } else if (inputValue.length > 10) {
      setInstructorid(inputValue.slice(0, 10));
    }
  };

  const handleContactNoChange = (e) => {
    const inputValue = e.target.value;
    const numericInput = inputValue.replace(/[^0-9]/g, ''); // Remove non-digit characters

    if (numericInput.length <= 10) {
      setContactno(numericInput);
      console.log("Contact:",contactno)
    }
  };
  const handleClasstypeChange = (e) => {
    const inputValue = e.target.value;
    const validatedInput = inputValue.replace(/[^a-zA-Z-\s]/g, ''); // Allow letters and hyphen "-"

    setClasstype(validatedInput);
    console.log("classtype:",classtype)
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const validatedInput = inputValue.replace(/[^a-zA-Z\s]/g, ''); // Allow letters only
    const maxLengthInput = validatedInput.slice(0, 15); // Restrict to 15 characters

    setName(maxLengthInput);
    console.log("name:",name)
  };
 
  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue)
  }
   
  const handleClassStyleChange = (e) => {
    const inputValue = e.target.value;
    const validatedInput = inputValue.replace(/[^a-zA-Z\s]/g, ''); // Allow letters only
    setClassstyle(validatedInput);
    console.log("Class style:",classstyle)
  };
 
  const combineTime = (start, end) => {
    if (start && end) {

      setatime(`${start} - ${end}`);
    }
  };

  return (
    <div>
              {error && (
                 <div id='error'
                style={{  color: "red",textAlign: "start" }} >&nbsp;&nbsp;&nbsp;&nbsp;{error.message}</div> )}
 <h1 style={{
      fontFamily: 'Aclonica',
      fontStyle: 'normal', fontSize: "25px", marginTop: "50px"
    }}>INSTRUCTOR</h1>
      <div style={{ display: "flex", flexDirection: "row" }}>
       
       {setvar===true && (<Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>EDIT INSTRUCTOR</Button>)}
       {setvar===false && (<Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>VIEW INSTRUCTOR</Button>)}
       {setvar===null && (<Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>ADD INSTRUCTOR</Button>)}
        <Button onClick={handleAllinstructor} style={{ background: "rgba(108, 188, 68, 0.5)", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>ALL INSTRUCTOR</Button>
      </div>

      <Grid container spacing={1} style={{ padding: "20px" }} >
        <Grid item xs={12} sm={3} >
          <Typography style={{ fontFamily: 'Aclonica' }}>INSTRUCTOR ID<span style={{ color: 'red' }}>*</span></Typography>
          <TextField placeholder='UGI044' InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={instructorid}
          disabled={setvar=== false}
          onChange={handleInstructorIdChange} style={{
            marginBottom: theme.spacing(3), backgroundColor: "white",
            borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: 'Aclonica'
          }}  />
          <Typography style={{ fontFamily: 'Aclonica' }}>CONTACT NO.<span style={{ color: 'red' }}>*</span></Typography>
          <TextField placeholder='+91 8123456789' InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} 
           value={contactno} disabled={setvar=== false} onChange={handleContactNoChange}
          //   onKeyPress={(e) => {
          //   const allowedKeys = /[0-9+]/;
          //   const key = e.key;
          //   if (!allowedKeys.test(key)) {
          //     e.preventDefault();
          //   }
          // }} 
           style={{
            marginBottom: theme.spacing(3), backgroundColor: "white",
            borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: 'Aclonica'
          }} />
          <Typography style={{ fontFamily: 'Aclonica' }}>CLASS TYPE<span style={{ color: 'red' }}>*</span></Typography>
          <TextField placeholder='Hip-hop' InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }}  value={classtype} disabled={setvar=== false} onChange={handleClasstypeChange} style={{
            marginBottom: theme.spacing(3), backgroundColor: "white",
            borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: 'Aclonica'
          }} />
           <Typography style={{ fontFamily: 'Aclonica' }}>AVAILABLE DAY<span style={{ color: 'red' }}>*</span></Typography>
           <FormControl disabled={setvar=== false} style={{ width: '220px' }}>
   
      <Select
    
        id="select"
        multiple
        value={selectedOptions}
        disabled={setvar=== false}
        renderValue={(selected) => selected.join(', ')}
        style={{ marginTop: '5px',backgroundColor: "white", borderColor: "white",fontSize: '14px',fontFamily: 'Aclonica'}}
        MenuProps={{
          style: { maxHeight: '300px', marginTop: '8px' },
        }}
      > 
       {options.map((option) => (
          <MenuItem key={option} value={option}  style={{ backgroundColor: "white",
          borderColor: "white", borderRadius: "5px", color: "#6CBC44", fontFamily: 'Aclonica', fontSize: '12px' }}>
            <Checkbox
              checked={selectedOptions.indexOf(option) !== -1}
              onChange={handleOptionToggle(option)}
              disabled={setvar=== false}
            />
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
          <Typography style={{  marginTop: '5px',fontFamily: 'Aclonica' }}>UPLOAD ID<span style={{ color: 'red' }}>*</span></Typography>
       {/*   <TextField placeholder='Upload ID for Personal Identification' value={uploadid} onChange={(e) => setUploadid(e.target.value)}
            style={{
              marginBottom: theme.spacing(3), backgroundColor: "white",
              borderColor: "white", borderRadius: "5px", color: "#000000", width: "220px", fontFamily: 'Aclonica'
            }}
            InputProps={{
              style: {

                fontFamily: "Aclonica",
                fontWeight: 400
              },
              endAdornment: (
                <InputAdornment position="end">
                  <img src={upload} alt="upload" />
                </InputAdornment>
              ),
            }} />*/}
              <TextField
            type="file"
          placeholder='Upload ID'
            id="upload-id"
            accept=".jpg, .jpeg, .png" 
            onChange={handleIdUpload}
            disabled={setvar=== false}
            style={{
              display: 'none',
            }}
          />
           
          <label htmlFor="upload-id">
            <TextField
              placeholder="Choose file"
              value={uploadid} // Display the file name
              disabled={setvar=== false}
              style={{
                marginBottom: theme.spacing(3),
                backgroundColor: 'white',
                borderColor: 'white',
                borderRadius: '5px',
                color: '#000000',
                width: '220px',
                fontFamily: "Aclonica"
              }}
              InputProps={{
                style: {

                  fontFamily: "Aclonica",
                  fontWeight: 400
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={upload} alt="uploadid" />
                  </InputAdornment>
                ),
              }}
            />
             {imageerror && <p style={{ color: 'red' }}>Please upload jpg/png files only</p>}
          </label>
         {/* {imageUrl && (
            <img
              src={imageUrl}
              alt="uploaded"
              style={{ maxWidth: '100%', marginTop: '20px' }}
            />
         )} */}


        </Grid>
        <Grid item xs={12} sm={3} >
          <Typography style={{ fontFamily: 'Aclonica' }}>Name<span style={{ color: 'red' }}>*</span></Typography>
          <TextField placeholder='Karan' InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={name} disabled={setvar=== false} onChange={handleNameChange} style={{
            marginBottom: theme.spacing(3), backgroundColor: "white",
            borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: 'Aclonica'
          }} />
          <Typography style={{ fontFamily: 'Aclonica' }}>INSTRUCTOR TYPE<span style={{ color: 'red' }}>*</span></Typography>

          <FormControl>

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              disabled={setvar=== false}
              onChange={handleChange}
              style={{ textAlign: "center", marginBottom: theme.spacing(2), fontFamily: 'Aclonica' }}
            >
              <FormControlLabel disabled={setvar=== false} value="INTERNAL" control={<Radio style={{ color: "orange" }} />} label={<span style={{ fontFamily: 'Aclonica' }}>INTERNAL</span>} />
              <FormControlLabel disabled={setvar=== false} value="EXTERNAL" control={<Radio style={{ color: "orange" }} />} label={<span style={{ fontFamily: 'Aclonica' }}>EXTERNAL</span>} />
            </RadioGroup>
          </FormControl>
          <Typography style={{ fontFamily: "Aclonica" }}>UPLOAD IMAGE<span style={{ color: 'red' }}>*</span></Typography>


          <TextField
            type="file"
            id="upload-image"
            disabled={setvar=== false}
            onChange={handleImageUpload}
            style={{
              display: 'none',
            }}
          />
          <label htmlFor="upload-image">
            <TextField
              placeholder="Choose file"
              disabled={setvar=== false}
              value={fileName} // Display the file name
              style={{
                marginBottom: theme.spacing(3),
                backgroundColor: 'white',
                borderColor: 'white',
                borderRadius: '5px',
                color: '#000000',
                width: '220px',
                fontFamily: "Aclonica"
              }}
              InputProps={{
                style: {

                  fontFamily: "Aclonica",
                  fontWeight: 400
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={upload} alt="upload" />
                  </InputAdornment>
                ),
              }}
            />
          </label>
         {/* {imageUrl && (
            <img
              src={imageUrl}
              alt="uploaded"
              style={{ maxWidth: '100%', marginTop: '20px' }}
            />
         )} */}

          <Typography style={{ fontFamily: "Aclonica" }}>CHOOSE PROFILE IMAGE<span style={{ color: 'red' }}>*</span></Typography>
          {/*<label htmlFor="upload-image-2" style={{ cursor: 'pointer' }}>
  <img src={image212} alt="logo" style={{ maxWidth: '100%', marginTop: '20px' }} />
</label>
<input type="file" id="upload-image-2" onChange={handleUpload} style={{ display: 'none' }} />
{profileImageUrl && (
  <img
    src={profileImageUrl}
    alt="uploaded"
    style={{ maxWidth: '100%', marginTop: '20px' }}
  />
)}*/}
      <section>
  {uploaderror &&
      <p style={{ color: 'red' }}>
        You can't upload more than 3 images! <br />
        <span>
          please delete them to add other images
        </span>
      </p>
   }

    {/* {selectedImages &&
      selectedImages.map((image, index) => {
        return (
          <div key={image} className="image">
            <img src={image} height="200" alt="upload" />
            <button onClick={() => deleteHandler(image)}>
              delete image
            </button>
            <p>{index + 1}</p>
          </div>
        );
      })} */}
       <div className="images-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
      {selectedImages && selectedImages.map((image, index) => (
  <div key={index} style={{ marginLeft: '10px' }} >
    <img src={image} height='60'width='60'  alt={`Image ${index}`} />
    <button onClick={() => handleRemoveImage(index)} style={{
         position: 'absolute',
         backgroundColor: 'transparent',
         border: 'none',
         cursor: 'pointer',
         zIndex: 1, // Add this        
         padding: 0, // Reset padding
        margin: -9
        }}>
          <ClearIcon/>
        </button>
  </div>

))}

{/* {selectedImages.map((image, index) => (
    <div key={index} style={{ position: 'relative', marginRight: "10px" }}>
      <img
        src={image}
        alt={`Selected ${index}`}
        style={{ width: "100px", height: "100px",borderRadius:"12px" }}
      />
      <button
        onClick={() => handleRemoveImage(index)}
        style={{
          position: 'absolute',
          top: '0px',
          right: '-1px',
          backgroundColor: '#0EBA70',
          border: 'none',
          cursor: 'pointer',
          color: 'white',
          borderRadius:"40px"
        }}
      >
        <ClearIcon />
      </button> */}

  </div>
 
  <label>
    <input
      type="file"
      name="images"
      onChange={onSelectFile}
      multiple
      accept="image/png , image/jpeg, image/webp"
      disabled={setvar=== false|| selectedImages.length >= 3}
      style={{ width: "30px", height: "30px", display: "none" }}
    />
    <img src={image212} alt="logo" style={{ width:"80px",borderRadius:"12px", marginTop: '20px' }} />
  </label>

  <br />
  {/* <button onClick={uploadImages}>Upload</button> */}
</section>



        </Grid>
        <Grid item xs={12} sm={3} >
          <Typography style={{ fontFamily: "Aclonica" }}>EMAIL<span style={{ color: 'red' }}>*</span></Typography>
          <TextField placeholder='karan@gmail.com' InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={email} disabled={setvar=== false} onChange={handleEmailChange}  style={{
            marginBottom: theme.spacing(3), backgroundColor: "white",
            borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: "Aclonica"
          }} />
          <Typography style={{ fontFamily: "Aclonica" }}>ADDRESS<span style={{ color: 'red' }}>*</span></Typography>
          <TextField placeholder='56, 4th cross Rd, Annanagar' InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={address} disabled={setvar=== false} onChange={(e) => setAddress(e.target.value)} style={{
            marginBottom: theme.spacing(3), backgroundColor: "white",
            borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: "Aclonica"
          }} />
          <Typography style={{ fontFamily: "Aclonica" }}>CLASS STYLE<span style={{ color: 'red' }}>*</span></Typography>
          <TextField placeholder='Beginner' InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={classstyle} disabled={setvar=== false} onChange={handleClassStyleChange} style={{
            marginBottom: theme.spacing(3), backgroundColor: "white",
            borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: "Aclonica"
          }} />
          <div>
        <Typography style={{ fontFamily: 'Aclonica' }}>AVAILABLE TIME<span style={{ color: 'red' }}>*</span></Typography>
        <input disabled={setvar=== false}
        onChange={(e)=>{setStartTime(e.target.value);  combineTime(e.target.value, endTime);}}
        value= {startTime}
        type="time"
        id="appt"
        name="appt"
        min="00:00"
        max="24:00"
        style={{
          marginBottom: theme.spacing(3), backgroundColor: "white", width: '16%',height: "10%",
          borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: "Aclonica"}}
        required
      />
      
      <span style={{ margin: '2px' }}> - </span>
      <input disabled={setvar=== false}
        onChange={(e)=>{setEndTime(e.target.value);  combineTime(startTime,e.target.value);}}
        type="time"
        value= {endTime}
        id="appt"
        name="appt"
        min="00:00"
        max="24:00"
        style={{
          marginBottom: theme.spacing(3), backgroundColor: "white", width: '16%',height: "10%",
          borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: "Aclonica"}}
        required
        
      />

        </div>
          <Typography style={{ fontFamily: "Aclonica" }}>INSTAGRAM ID</Typography>
          <TextField placeholder='@GRACIOUSGAMBLER' InputProps={{
            style: {

              fontFamily: "Aclonica",
              fontWeight: 400
            }
          }} value={instagramid} disabled={setvar=== false}  onChange={(e) => setInstagramid(e.target.value)} style={{
            marginBottom: theme.spacing(3), backgroundColor: "white",
            borderColor: "white", borderRadius: "5px", color: "#000000", fontFamily: "Aclonica"
          }} />
        </Grid>
        <div>
          <Typography style={{ marginLeft: theme.spacing(1), fontFamily: "Aclonica" }}>ABOUT INSTRUCTOR</Typography>
          <textarea className={classes.textarea} value={aboutinstructor} onChange={(e) => setAboutinstructor(e.target.value)} 
          disabled={setvar=== false}
          style={{
            marginLeft: theme.spacing(1),
            minHeight: "100px",
            maxHeight: "auto", fontFamily: "Aclonica", marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000"
          }} />
        </div>
      </Grid>

      <div style={{ textAlign: "center" }}>
        <Button type="submit" onClick={(handleInstructorCancel)} style={{ fontFamily: "Aclonica", backgroundColor: "#FF0404", width: "120px", height: "40px", color: "white", borderRadius: "20px" }}>CANCEL</Button>
     { setvar===true && (<Button type='submit' onClick={(handleInstructorEdit)} style={{ fontFamily: "Aclonica", backgroundColor: "#328906", width: "120px", height: "40px", color: "white", borderRadius: "20px", marginLeft: theme.spacing(2) }}>UPDATE</Button>)}
     { setvar===null && (<Button type='submit' onClick={(handleInstructorAdd)} style={{ fontFamily: "Aclonica", backgroundColor: "#328906", width: "120px", height: "40px", color: "white", borderRadius: "20px", marginLeft: theme.spacing(2) }}>ADD</Button>)}
       {/* <Button type='submit' onClick={handleInstructorAdd} style={{ fontFamily: "Aclonica", backgroundColor: "#328906", width: "120px", height: "40px", color: "white", borderRadius: "20px", marginLeft: theme.spacing(2) }}>ADD</Button> */}
      </div>
    </div>
  )
}


export default Instructorsadd