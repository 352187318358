import { Button, Grid, InputLabel, inputLabelClasses, MenuItem, NativeSelect, Select, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
//import theme from '../../../theme'
import api from "../../api.json";
import axios from "axios";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
const theme = createTheme();
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(0),
      background:"white",
      marginLeft:theme.spacing(-2),
      width:"240px",
  
    },
  
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      minHeight: 30,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
     'Aclonica',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
  
      },
  
    },
  
  }));
const useStyles = makeStyles((theme) => ({
 
      
    root: {
        marginTop: '10px',
        fontWeight: 400,
        fontFamily: 'Aclonica'
      },
    textarea: {
        width: "240px",
        [theme.breakpoints.down("xs")]: {
            width: "220px",
        },
    }

}));
const Editpasses= () => {
    const history = useHistory()
    const classes = useStyles();
    const [passnumber, setPassnumber] = useState("");
    const [passtype, setPasstype] = useState("");
    const [name, setName] = useState("");
    const [noofclasses, setNoofclasses] = useState("")
    const [description, setDescription] = useState("")
    const [passescost, setPassescost] = useState("")
    const [data, setData] = useState([]);
    const [message, setMessage] = useState("");
    const [value, setValue] = React.useState('');
    const[cate,setCate]=useState([]);
  const[id,setID]=useState(null)
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const getpassesdetails = (e) => {
        axios
            .get(api.baseUrl + "/api/admin/view_passes")
            .then((response) => {

                setData(response.data.data);
                setCate(response.data.data);
                console.log("result123-->", response.data.data)
            })
            .catch((err) => {
                console.log("error", err);
            });

    }
    useEffect(() => {

        getpassesdetails()
    }, []);
    useEffect(() => {
        setID(localStorage.getItem('ID'))
        setPassnumber(localStorage.getItem("passno"))
        setPasstype(localStorage.getItem("passtype"))
        setPassescost(localStorage.getItem("passcost"))
        setName(localStorage.getItem("name"))
        setNoofclasses(localStorage.getItem("no.ofclasses"))
        setValue(localStorage.getItem("status"))
        setDescription(localStorage.getItem("description2"))
    
    }, []);
        
        let handleClick = async (e) => {
            e.preventDefault();
    
    
    
            try {
    
                let res = await fetch(api.baseUrl + "/api/admin/edit_passes/" + id, {
                    method: "PUT",
    
                    headers: {
                        'Content-Type': 'application/json',
    
                    },
                    body: JSON.stringify({
                        pass_number:passnumber,
                        pass_type:passtype,
                        name: name,
                        no_of_classes: noofclasses,
                        
                        pass_cost: passescost,
                        status: value,
                        description:description
                    }),
                });
    
    
    
                let resJson = await res.json();
                if (res.status === 200) {
    
                    setMessage("User created successfully");
                    history.push("/passeslist")
                } else {
                    setMessage("Some error occured");
                }
            } catch (err) {
                console.log(err);
    
    
            }
        };
   
    return (
        <div> <h1 style={{
            fontFamily: 'Aclonica',
            fontStyle: 'normal', fontSize: "25px", marginTop: "50px"
        }}>PASSES</h1>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button style={{ background: " #6CBC44", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>ADD PASSES</Button>
                <Button style={{ background: "rgba(108, 188, 68, 0.5)", color: "#FFFFFF", width: 450, fontWeight: 600, fontFamily: 'Aclonica', height: "25px" }}>ALL PASSES</Button>
            </div>
            <Grid container spacing={1} style={{ padding: '20px' }}>
                <Grid item xs={12} sm={6} >
                <Typography style={{ fontFamily: 'Aclonica'}}>PASS NUMBER</Typography>
                    <TextField placeholder='UGP054' InputProps={{ 
                        style: {
                     
                          fontFamily:"Aclonica",
                          fontWeight: 400
                        }
                      }} value={passnumber}
                        onChange={(e) => setPassnumber(e.target.value)} style={{ minWidth: "240px", fontFamily: 'Aclonica', marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
                   
                    <Typography style={{ fontFamily: 'Aclonica'}}>NAME</Typography>
                    <TextField placeholder='Free text'  InputProps={{ 
                        style: {
                        
                          fontFamily:"Aclonica",
                          fontWeight: 400
                        }
                      }} value={name} 
                        onChange={(e) => setName(e.target.value)} style={{ minWidth: "240px", marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
                   
                   <Typography style={{ fontFamily: 'Aclonica', }} > DESCRIPTION</Typography>
                    <textarea className={classes.textarea} value={description} onChange={(e) => setDescription(e.target.value)} style={{


                        minHeight: "100px",
                        maxHeight: "auto", marginBottom: theme.spacing(3), fontFamily: "Aclonica", backgroundColor: "white", borderColor: "white", borderRadius: "5px"
                    }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography style={{ fontFamily: 'Aclonica',}}>PASS TYPE</Typography>
                <FormControl className={classes.error} style={{
                    marginLeft: theme.spacing(2),
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1), minWidth: "250px",fontFamily: 'Aclonica'
                  }}>
                    <InputLabel id="demo-simple-select-label" sx={{ color: "#590F72", fontWeight: 500 }} InputLabelProps={{
                      sx: {
                        color: "#590F72",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#590F72",
                          fontWeight: 500,
                          fontSize: 16,
                          fontFamily: 'Aclonica'
                        }
                      }
                    }}></InputLabel>
                    <NativeSelect id="select" 
                    
                      value={passtype} onChange={(e)=>setPasstype(e.target.value)}
                      InputLabelProps={{
                          sx: {



                              [`&.${inputLabelClasses.shrink}`]: {
                                fontFamily: 'Aclonica',
                                  fontWeight: 600,
                                  fontSize: 16
                              }
                          }
                      }}

                      className={classes.select}
                      input={<BootstrapInput />}
                    >
                      <option value="" style={{fontFamily: 'Aclonica',background:"#6CBC44",}}>Drop-in</option>
                      <option value="Weekly" >Weekly</option>
             <option value="Monthly" >Monthly</option>
                    </NativeSelect>
                  </FormControl>
                    <Typography style={{ marginTop: theme.spacing(3) , fontFamily: 'Aclonica',}}>NO. OF CLASSES</Typography>
                    <TextField placeholder="1"  InputProps={{ 
                        style: {
                       
                          fontFamily:"Aclonica",
                          fontWeight: 400
                        }
                      }} value={noofclasses} onChange={(e) => setNoofclasses(e.target.value)} style={{ minWidth: "240px", fontFamily: 'Aclonica', marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
                    <Typography style={{ fontFamily: 'Aclonica',}}>PASS COST</Typography>
                    <TextField placeholder='Rs.80'  InputProps={{ 
                        style: {
                        
                          fontFamily:"Aclonica",
                          fontWeight: 400
                        }
                      }} value={passescost} onChange={(e) => setPassescost(e.target.value)} style={{ minWidth: "240px", fontFamily: 'Aclonica', marginBottom: theme.spacing(3), backgroundColor: "white", borderColor: "white", borderRadius: "5px", color: "#000000" }} />
                    <Typography style={{ fontFamily: 'Aclonica',}}>STATUS</Typography>
                    <FormControl style={{ fontFamily: 'Aclonica',}}>

                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                            style={{ textAlign: "center", marginBottom: theme.spacing(2), fontFamily: 'Aclonica', }}
                        >
                            <FormControlLabel value="ACTIVE" style={{ fontFamily: 'Aclonica',}} control={<Radio style={{ color: "orange", fontFamily: 'Aclonica' }} />}
                            label={<span style={{fontFamily: 'Aclonica'}}>ACTIVE</span>} />
                            <FormControlLabel value="INACTIVE" style={{ fontFamily: 'Aclonica',}} control={<Radio style={{ color: "orange", fontFamily: 'Aclonica' }} />}
                             label={<span style={{fontFamily: 'Aclonica'}}>INACTIVE</span>}/>
                        </RadioGroup>
                    </FormControl>

                </Grid>

            </Grid>

            <div style={{ textAlign: "center" }}>
                <Button type="submit" style={{ backgroundColor: "#FF0404", width: "120px", height: "40px", color: "white", borderRadius: "20px", fontFamily: 'Aclonica', }}>CANCEL</Button>
                <Button type='submit' onClick={ handleClick} style={{ backgroundColor: "#328906", width: "120px", height: "40px", color: "white", 
                fontFamily: 'Aclonica', borderRadius: "20px", marginLeft: theme.spacing(2) }}>UPDATE</Button>
            </div>
        </div>
    )
}




export default Editpasses