
import { Button, Grid, TextField, useTheme  } from '@mui/material';
import { Typography, useMediaQuery } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import user from "./BodyComponent/assets/bg.png"
import { useHistory} from 'react-router-dom';
import axios from "axios"
import api from "./api.json"
import uglogo from "./BodyComponent/assets/uglogo.png"
//import theme from "../theme";
import { makeStyles } from '@material-ui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
  typography: {
    fontFamily: 'Arial',
  },
  
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Login = ({ handleLoginSuccess,handleLogout }) => {
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("")
  const [message, setMessage] = useState("");
  const history = useHistory();
  const [error, setError] = useState({
    status: false,


    msg: "",
    type: ""
  })
  const [inputWidth, setInputWidth] = useState("60%");
   const handlefunction=()=>{
      history.push('/dashboard')
      window.location.reload()
    }
  let handleLogin = async (e) => {
    console.log("sidebar")
    console.log("login details------>", {
      password: password,
      email: email,

    })
    e.preventDefault();
 
    try {
      let res = await fetch(api.baseUrl + "/api/admin/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

        },
        body: JSON.stringify({
          password: password,
          email: email,

        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {


        setPassword("");
        setEmail("");
        setMessage("User logged in successfully");
        handleLoginSuccess(); 
        history.push("/dashboard")
        window.location.reload()
       // alert("User logged in successfully")
        console.log(resJson)
      } else {
        setMessage("Some error occured");
        console.log("errrrrr", resJson.message)

        setError({ status: true, message: resJson.message, type: 'error' });

      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    // Set the background color of the body element to green
    document.body.style.backgroundColor = "#000000";

    // Remove the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);
  useEffect(() => {
    const updateInputWidth = () => {
      if (window.innerWidth < 320) {
        setInputWidth("60%");
      } else if (window.innerWidth < 1024) {
        setInputWidth("70%");
      } else {
        setInputWidth("440px");
      }
    };

    window.addEventListener("resize", updateInputWidth);
    updateInputWidth();

    return () => {
      window.removeEventListener("resize", updateInputWidth);
    };
  }, []);
  const handleSubmit = () => {
    console.log("login details:", email, password)

  }


    
      return (
        <ThemeProvider theme={theme}>
        <div>
          <Box
            display="grid"
            gridTemplateColumns={{ xs: 'repeat(12, 1fr)', sm: 'repeat(12, minmax(0, 1fr))' }}
          >
            <Box gridColumn={{ xs: 'span 12', md: 'span 6', sm: 'span 12' }} style={{ backgroundColor: '#000000' }}>
              <div style={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                height: '100vh'
              }}>
            <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '450px',
             width: '100%',marginTop:theme.spacing(8) }}
             onSubmit={handleLogin}>
              <Typography style={{color:"white",marginLeft:theme.spacing(-40)}}>Email</Typography>
              <TextField style={{color:"black",background:"white",marginBottom:theme.spacing(4),borderRadius:"12px"}} type="email" value={email} onChange={(e)=>setEmail(e.target.value)} fullWidth/>
              <Typography style={{color:"white",marginLeft:theme.spacing(-37)}}>Password</Typography>
              <TextField style={{color:"black",background:"white",marginBottom:theme.spacing(4),borderRadius:"12px"}} type="password" value={password} onChange={(e)=>setPassword(e.target.value)} fullWidth/>
              
                  <div >   {error.status ? <div id='error'
                    style={{ color: "red", fontWeight: "bold", textAlign: "center",
                    paddingBottom:"12px" }} ><i class="bi bi-exclamation-circle"
                      style={{ fontWeight: "bold" }}></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}</div> : ''}
                  </div>
              <Button type='submit' fullWidth  variant='outlined' style={{color:"white",background:"green",
              height:"50px",borderRadius:"12px"}}>Login</Button>
              </form>
              </div>
            </Box>
            <Box gridColumn={{ xs: 'span 12', md: 'span 6', sm: 'span 12' }}>
              <img src={uglogo} style={{ height: "80%", width: "100%" }} />
            </Box>
          </Box>
        </div>
        </ThemeProvider>
     
    
  
  
  
  )
}

export default Login